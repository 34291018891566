.fit {
  min-height: calc(100vh - 88px);
}

@media screen and (max-width:1024px) {
  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100vh;
    z-index: 9;
    background: rgba(106, 52, 31, 0.4);
  }

  .nav-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 99;
    background: rgba(255, 255, 255, 0);
  }

  .inner-nav {
    max-width: 90% !important;
  }

  .mobile-image-cart img,
  .mobile-image-cart>span {
    width: 80px !important;
    height: 80px !important;
    -o-object-fit: cover !important;
    object-fit: cover !important;
    -o-object-position: top !important;
    object-position: top !important;
  }
}