@tailwind base;
@import './base.css';

@tailwind components;
@import './components.css';

@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Black.woff2') format('woff2'),
    url('/assets/font/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-BoldItalic.woff2') format('woff2'),
    url('/assets/font/Inter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ExtraBoldItalic.woff2') format('woff2'),
    url('/assets/font/Inter-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ExtraBold.woff2') format('woff2'),
    url('/assets/font/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-BlackItalic.woff2') format('woff2'),
    url('/assets/font/Inter-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Bold.woff2') format('woff2'),
    url('/assets/font/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Italic.woff2') format('woff2'),
    url('/assets/font/Inter-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ExtraLightBETA.woff2') format('woff2'),
    url('/assets/font/Inter-ExtraLightBETA.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ExtraLightItalicBETA.woff2') format('woff2'),
    url('/assets/font/Inter-ExtraLightItalicBETA.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Medium.woff2') format('woff2'),
    url('/assets/font/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-LightBETA.woff2') format('woff2'),
    url('/assets/font/Inter-LightBETA.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-LightItalicBETA.woff2') format('woff2'),
    url('/assets/font/Inter-LightItalicBETA.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-MediumItalic.woff2') format('woff2'),
    url('/assets/font/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-SemiBold.woff2') format('woff2'),
    url('/assets/font/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ThinBETA.woff2') format('woff2'),
    url('/assets/font/Inter-ThinBETA.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-Regular.woff2') format('woff2'),
    url('/assets/font/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/font/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter-ThinItalicBETA.woff2') format('woff2'),
    url('/assets/font/Inter-ThinItalicBETA.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

/* html { */
/* Used to prevent the scroll issue with applicable dialog box close */
/* overflow visible is the default value and used to override the overflow hidden on the html tag */
/* This needs to be checked again */
/* overflow: visible !important; */
/* } */

body {
  background: #fff;
}

/* body > img,
body > iframe {
  height: 0px !important;
} */

.bg-primary {
  background: rgb(236, 94, 40);
  border: 1px solid rgb(236, 94, 40);
}

.swiper-button-next {
  color: white !important;
  background: rgba(0, 0, 0, 0.2);
  width: 50px !important;
  height: 50px !important;
  line-height: 50px;
  border-radius: 25px;
  font-size: 28px;
  text-align: center;
  transition: background 0.45s cubic-bezier(0.22, 0.61, 0.35, 1);
}

/* Hide the vertical scrollbar */
.hide-scrollbar-visibility::-webkit-scrollbar {
  width: 0;
  background: transparent;
  /* Optional: Set a background color if desired */
}

/* Optional: Hide the horizontal scrollbar */
.hide-scrollbar-visibility::-webkit-scrollbar-horizontal {
  display: none;
}

/* Optional: Hide the scrollbar thumb (the draggable part) */
.hide-scrollbar-visibility::-webkit-scrollbar-thumb {
  display: none;
}

.mySwiper {
  z-index: 0 !important;
}

#search-input {
  margin-top: 0px;
}

.filter-input {
  display: none;
}

.swiper-button-next::after {
  font-size: 25px !important;
}

.swiper-button-prev::after {
  font-size: 25px !important;
}

.swiper-button-prev {
  color: white !important;
  background: rgba(0, 0, 0, 0.2);
  width: 50px !important;
  height: 50px !important;
  line-height: 50px;
  border-radius: 25px;
  font-size: 28px;
  text-align: center;
  transition: background 0.45s cubic-bezier(0.22, 0.61, 0.35, 1);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  margin-top: 0px !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide.cs-mr-1 {
  margin-left: 1rem;
}

.swiper-slide.no-mr {
  margin-right: 0 !important;
}

@media screen and (min-width: 768px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .swiper-slide .img-new-s {
    object-fit: none;
  }

  #view-all-result-mobile {
    position: fixed;
    bottom: 22px;
    width: 88%;
    top: aut;
  }
}

.max-panel {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.max-panel::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar,
.max-panel::-moz-scrollbar,
.custom-scroll::-moz-scrollbar,
.max-panel::-ms-scrollbar,
.custom-scroll::-ms-scrollbar {
  width: 6px;
}

.max-panel::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track,
.max-panel::-moz-scrollbar-track,
.custom-scroll::-moz-scrollbar-track,
.max-panel::-ms-scrollbar-track,
.custom-scroll::-ms-scrollbar-track {
  background: #fff;
  opacity: 1;
}

.max-panel::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb,
.max-panel::-moz-scrollbar-thumb,
.custom-scroll::-moz-scrollbar-thumb,
.max-panel::-ms-scrollbar-thumb,
.custom-scroll::-ms-scrollbar-thumb {
  background: #d3d3d36e !important;
  width: 6px;
  border-radius: 6px;
}

.max-panel::-webkit-scrollbar {
  width: 6px;
}

.max-panel::-webkit-scrollbar-track {
  background: #fff;
  opacity: 1;
}

.max-panel::-webkit-scrollbar-thumb {
  background: #d3d3d3fb !important;
  width: 6px;
  border-radius: 6px;
}

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#payment-submit {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#payment-submit:hover {
  filter: contrast(115%);
}

#payment-submit:disabled {
  opacity: 0.5;
  cursor: default;
}

/* stripe-spinner/processing state, errors */
.stripe-spinner,
.stripe-spinner:before,
.stripe-spinner:after {
  border-radius: 50%;
}

.stripe-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripe-spinner:before,
.stripe-spinner:after {
  position: absolute;
  content: '';
}

.stripe-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripe-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 80vw;
    min-width: initial;
  }
}

.line-clip-7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  max-height: 210px;
}

.image-container {
  width: 100%;
  max-width: 100%;
}

.image-container>div,
.image-container>span {
  position: unset !important;
}

.image-container .image {
  object-fit: cover !important;
  width: 100% !important;
  position: relative !important;
  object-position: top !important;
  height: auto !important;
}

.image-container span {
  padding: 0 !important;
}

.sm\:h-64 {
  height: 16rem !important;
}

.sm\:h-72 {
  height: 18rem !important;
}

.sm\:min-h-screen {
  min-height: 100vh !important;
}

.h-full {
  height: 100% !important;
}

.text-orange {
  color: #ec5e28 !important;
}

.fill-orange {
  fill: #ec5e28 !important;
}

.z-99 {
  z-index: 999999 !important;
}

.bg-orange {
  background-color: #eb5b25 !important;
}

.border-orange {
  border-color: #ec5e28 !important;
}

.bg-orange:hover {
  background-color: #c73f0a !important;
}

.sm\:h-44 {
  height: 11rem !important;
}

@media screen and (max-width: 767px) {
  .min-mobile-pdp img {
    min-height: 0 !important;
  }
}

@media (min-width: 640px) {
  .sm\:h-auto {
    height: auto !important;
  }
}

.image-container.pdp span {
  padding: 0 !important;
}

.text-orange {
  color: #eb5b25 !important;
}

.z-99 {
  z-index: 999 !important;
}

.bg-orange {
  background-color: #ec5e28 !important;
}

.border-orange {
  border-color: #ec5e28 !important;
}

.bg-orange:hover {
  background-color: #c73f0a !important;
}

.hover\:bg-orange:hover {
  background-color: #ff5b1d !important;
}

.hover\:border-orange:hover {
  border-color: #ec5e28 !important;
}

.hover\:text-orange:hover {
  color: #ec5e28 !important;
}

.table {
  width: 100% !important;
}

.bg-selected-facet {
  background-color: rgba(236, 94, 40, 0.08);
  padding: 5px;
}

.bg-selected-category {
  background-color: rgba(236, 94, 40, 0);
  padding: 2px;
  font-weight: 600 !important;
}

.bg-selected-sort {
  background-color: rgba(236, 94, 40, 0.08);
}

.bg-nonselected-facet {
  background-color: transparent;
  padding: 2px;
}

.max-w-md {
  max-width: 34.5rem !important;
}

.z-999 {
  z-index: 9999999 !important;
}

.heading {
  position: relative;
  color: rgb(19, 19, 21);
  text-align: center;
}

.heading-h2 {
  position: relative;
  font-size: 2rem !important;
  font-weight: 700;
  color: rgb(19, 19, 21);
  text-align: left;
}

.heading-h2 span {
  padding: 20px 20px 20px 0;
  position: relative;
  z-index: 9;
}

.heading-h2:after {
  position: absolute;
  width: 100% !important;
  height: 2px;
  content: '';
  background-color: #ef6902;
  top: 20px;
  left: 0px;
}

.heading-h3 {
  position: relative;
  font-size: 2.5rem !important;
  font-weight: 500;
  color: rgb(19, 19, 21);
  text-align: right;
}

.heading-h3 span {
  padding: 10px 0px 10px 20px;
  position: relative;
  z-index: 9;
}

.heading-h3:after {
  position: absolute;
  width: 85% !important;
  height: 2px;
  content: '';
  background-color: #ef6902;
  top: 30px;
  right: 0px;
}

@media screen and (max-width: 767px) {
  .social-section {
    margin: 0 auto;
  }

  .min-h-40 {
    min-height: 40px;
  }

  .menu-links-ul,
  .mob-link-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  .mob-link-2 li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mob-ml-0 {
    margin-left: 0 !important;
  }

  .min-height-4 {
    height: 450px;
  }

  .sm\:h-72 {
    height: 12rem !important;
  }

  .heading-h2 {
    position: relative;
    font-size: 1rem !important;
    font-weight: 700;
    color: rgb(19, 19, 21);
    text-align: left;
  }

  .heading-h2 span {
    padding: 4px 10px 4px 0;
    position: relative;
    z-index: 9;
  }

  .heading-h2:after {
    position: absolute;
    width: 100% !important;
    height: 2px;
    content: '';
    background-color: #ef6902;
    top: 15px;
    left: 0px;
  }

  .heading-h3 {
    position: relative;
    font-size: 1.5rem !important;
    font-weight: 500;
    color: rgb(19, 19, 21);
    text-align: right;
  }

  .heading-h3 span {
    padding: 10px 0px 10px 20px;
    position: relative;
    z-index: 9;
  }

  .heading-h3:after {
    position: absolute;
    width: 85% !important;
    height: 2px;
    content: '';
    background-color: #ef6902;
    top: 30px;
    right: 0px;
  }

  #view-all-result-mobile {
    position: fixed;
    bottom: 22px;
    width: 88%;
    top: aut;
  }
}

.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.7);
}

.image-container {
  width: 100%;
}

.image-container>div {
  position: unset !important;
}

.image-container .image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.image-banner-container {
  width: 100%;
}

.image-banner-container .image {
  object-fit: contain;
  /* width: 100% !important; */
  position: relative !important;
  /* height: unset !important; */
}

.image-list {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.pdp-color-variant {
  height: 103px !important;
  margin: 3px 0px !important;
}

.pdp-color-variant img {
  width: 58px !important;
  height: 103px !important;
  object-fit: contain;
  padding: 0 !important;
  border: 0px !important;
}

@media screen and (min-width: 1024px) {
  .long-image .bhdLno {
    height: 560px !important;
    width: auto !important;
    object-fit: cover !important;
  }

  .long-image .image-container {
    margin-bottom: -7px !important;
  }
}

.new-coupon-layout-wrapper .swiper-button-next::before {
  content: '';
  background: linear-gradient(270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: -94px !important;
  z-index: -2;
  width: 126px;
  height: 176px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000000 !important;
  background: rgb(255 255 255 / 20%);
  width: 22px !important;
  height: 22px !important;
  line-height: 20px;
  border-radius: 25px;
  font-size: 16px;
  text-align: center;
  transition: background 0.45s cubic-bezier(0.22, 0.61, 0.35, 1);
  border: 2px solid #000;
  position: relative;
  top: 65% !important;
}

.swiper-button-next::before {
  content: '';
  background: linear-gradient(270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%);
  height: 85px;
  width: 100px !important;
  position: absolute;
  right: -40px !important;
  top: -35px !important;
  z-index: -2;
}

.swiper-button-disabled {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 13px !important;
  font-weight: bold !important;
}

.specifications .swiper-button-next:before {
  content: '';
  background: linear-gradient(270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 100px !important;
  position: absolute;
  right: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.specifications .swiper-button-prev:before {
  content: '';
  background: linear-gradient(-270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 100px !important;
  position: absolute;
  left: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.specifications .swiper-button-next,
.specifications .swiper-button-prev {
  color: #000000 !important;
  background: rgb(255 255 255 / 20%);
  border: 2px solid #000;
  width: 35px !important;
  height: 35px !important;
  top: 50% !important;
}

.specifications .swiper-button-next:after,
.specifications .swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: bold !important;
}

.buylook .swiper-button-disabled {
  display: flex !important;
}

.buylook .swiper-button-next:before {
  content: '';
  background: linear-gradient(270deg, #ffffff 99%, rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 100px !important;
  position: absolute;
  right: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.buylook .swiper-button-prev:before {
  content: '';
  background: linear-gradient(-270deg,
      #ffffff 99%,
      rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 100px !important;
  position: absolute;
  left: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.buylook .swiper-button-next,
.buylook .swiper-button-prev {
  color: #000000 !important;
  background: rgb(255 255 255 / 20%);
  border: 2px solid #000;
  width: 35px !important;
  height: 35px !important;
  top: 50% !important;
  opacity: 1 !important;
}

.buylook .swiper-button-next:after,
.buylook .swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: bold !important;
}

.buylook .swiper-button-prev {
  display: flex !important;
}

.section-devider {
  width: 100%;
  float: left;
  background: #f7f7f7;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.04);
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 20px;
  margin: 50px 0;
}

.section-devider-sm {
  width: 100%;
  float: left;
  background: #f7f7f7;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.04);
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 20px;
  margin: 25px 0;
}

.section-devider-sm-btm {
  width: 100%;
  float: left;
  background: #f7f7f7;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.04);
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 20px;
  margin: 0 0 25px;
}

.default-sm .swiper-button-next:before {
  content: '';
  background: linear-gradient(270deg, #ffffff 99%, rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 10px !important;
  position: absolute;
  right: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.default-sm .swiper-button-prev:before {
  content: '';
  background: linear-gradient(-270deg,
      #ffffff 99%,
      rgba(255, 255, 255, 0) 100%);
  height: 500px;
  width: 10px !important;
  position: absolute;
  left: -45px !important;
  top: -220px !important;
  z-index: -2;
}

.default-sm .swiper-button-next,
.default-sm .swiper-button-prev {
  color: #000000 !important;
  background: rgb(255 255 255 / 20%);
  border: 2px solid #000;
  width: 35px !important;
  height: 35px !important;
  top: 50% !important;
}

.default-sm .swiper-button-next:after,
.default-sm .swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: bold !important;
}

.text-8xl {
  font-size: 6rem !important;
  font-weight: 500 !important;
}

.swiper-quick-view .swiper,
.swiper-quick-view .swiper .swiper-wrapper,
.swiper-quick-view .swiper .swiper-wrapper .swiper-slide {
  min-height: 90px !important;
}

.text-brown-light {
  color: rgba(37, 16, 0, 0.56) !important;
}

.text-brown-xlight {
  color: rgba(37, 16, 0, 0.4) !important;
}

.text-brown {
  color: rgba(37, 16, 0, 0.64) !important;
}

.border-brown-light {
  border-color: rgba(106, 52, 31, 0.4) !important;
}

.text-xxs {
  font-size: 12px !important;
}

.specifications {
  min-height: 460px !important;
}

@media screen and (min-width: 1024px) {
  .side-panel-lg {
    width: 642px !important;
    min-width: 642px !important;
  }
}

@media screen and (min-width: 1280px) {
  .long-image .pdp-image {
    height: auto !important;
    width: 100% !important;
  }
}

.buylook {
  min-height: 480px !important;
}

@media screen and (max-width: 767px) {
  .buylook {
    min-height: 380px !important;
  }

  .long-image .pdp-image {
    height: auto !important;
    width: 100% !important;
  }

  .mobile-slider-no-arrow .swiper-button-prev,
  .mobile-slider-no-arrow .swiper-button-next,
  .specifications .swiper-button-prev,
  .specifications .swiper-button-next,
  .buylook .swiper-button-prev,
  .buylook .swiper-button-next {
    display: none !important;
  }

  .specifications,
  .buylook {
    min-height: 0px !important;
  }

  .specifications img,
  .buylook img {
    object-fit: cover !important;
  }

  .specifications img.spec-image-s {
    object-fit: none !important;
  }

  .section-devider {
    height: 20px;
    margin: 20px 0;
  }
}

.bg-grren-review {
  background: #00890e !important;
}

.border-green-review {
  border-color: #00890e !important;
}

.bg-brown {
  background: #251000 !important;
}

.text-dark-brown {
  color: #251000 !important;
}

.cat-sec-link .swiper-button-next,
.cat-sec-link .swiper-button-prev {
  top: 30px !important;
}

.cat-sec-link .swiper-button-prev::before {
  content: '';
  background: linear-gradient(270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%);
  height: 40px;
  width: 40px !important;
  position: absolute;
  right: -23px !important;
  top: -11px !important;
  z-index: -2;
}

.cat-sec-link .swiper-slide {
  margin-right: 5px !important;
}

@media screen and (max-width: 1520px) and (min-width: 1024px) {
  .sm\:w-3\/4 {
    width: 75% !important;
  }
}

.bg-footer {
  background: #f1ece2;
}

@media screen and (min-width: 1025px) and (max-width: 1320px) {
  .grid .product-side-filter {
    grid-column: span 3 / span 3;
  }

  .grid .product-card-list {
    grid-column: span 9 / span 9;
  }

  .grid.product-listing-main {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .info-img {
    width: 50%;
  }

  .f-info-div {
    padding: 10px !important;
  }

  .f-label-name {
    width: 80% !important;
  }
}

.home-banner-swiper .swiper-button-next,
.home-banner-swiper .swiper-button-prev {
  top: 52% !important;
  background: #ffffff;
  width: 32px !important;
  height: 32px !important;
}

.home-banner-swiper .swiper-button-prev {
  left: 35px !important;
}

.home-banner-swiper .swiper-button-next {
  right: 35px !important;
}

.home-banner-swiper .swiper-button-next::before,
.home-banner-swiper .swiper-button-prev::before {
  background-color: transparent !important;
  background: transparent !important;
}

.home-category span {
  margin: 0 auto !important;
}

.home-banner-swiper-colletion .swiper-button-next,
.home-banner-swiper-colletion .swiper-button-prev {
  top: 52% !important;
  background: #ffffff;
  width: 32px !important;
  height: 32px !important;
}

.home-banner-swiper-colletion .swiper-button-prev {
  left: 10px !important;
}

.home-banner-swiper-colletion .swiper-button-next {
  right: 10px !important;
}

.home-banner-swiper-colletion .swiper-button-next::before,
.home-banner-swiper-colletion .swiper-button-prev::before {
  background-color: transparent !important;
  background: transparent !important;
}

.home-category span {
  margin: 0 auto !important;
}

@media screen and (max-width: 1480px) {
  .home-banner-swiper .swiper-button-prev {
    left: 18px !important;
  }

  .home-banner-swiper .swiper-button-next {
    right: 18px !important;
  }
}

.bg-gray-dark {
  background: rgba(37, 16, 0, 0.24) !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid .product-side-filter {
    grid-column: span 4 / span 4;
  }

  .grid .product-card-list {
    grid-column: span 8 / span 8;
  }

  .grid.product-listing-main {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.min-product-info {
  position: sticky;
  display: none;
}

.min-product-info.active {
  position: sticky;
  display: flex;
}

.min-product-info-plp {
  position: sticky;
  display: none;
}

.min-product-info-plp.active {
  position: sticky;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .lookbook-swiper .swiper-wrapper {
    display: contents !important;
  }
}

.image-overlay,
.image-deskOverlay,
.no-overlay-img {
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
}

.image-overlay a:after,
.image-deskOverlay a::after {
  content: '';
  width: 100%;
  height: 50%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.swiper-grid-column>.swiper-wrapper {
  flex-direction: unset !important;
}

@media screen and (min-width: 767px) {

  .sm\:grid-cols-8,
  .sm\:grid-cols-16 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

/* checkout css start */
.floating-label {
  position: relative;
}

.floating-label label {
  position: absolute;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  top: 50%;
  left: 0;
  z-index: 9;
  transform: translateY(-50%);
  pointer-events: none;
  color: #251000;
  opacity: 0.56;
  font-size: 14px;
}

.floating-label input {
  min-height: 56px;
  background: #f3f3f3;
  border-radius: 2px;
}

.floating-label input:focus,
.floating-label input:not(:placeholder-shown) {
  padding-top: 1.4rem;
}

.floating-label input:focus~label,
.floating-label input:not(:placeholder-shown)~label {
  top: 24px;
  left: 5px;
  font-size: 10px;
  transition: all 0.2s ease-in-out;
  color: #251000;
}

.floating-label input::placeholder {
  color: transparent;
}

.checkbox-c label {
  margin-top: -7px;
  display: inline-block;
  vertical-align: middle;
}

.bg-footer-chk {
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.04);
}

.bg-selected-address {
  background: rgba(236, 94, 40, 0.08);
}

.methodName {
  color: #000;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.paymentBox.paymentbox-sec .control {
  padding: 0 !important;
}

.control input.pay-inpt {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

.paymentBox .control__indicator {
  width: 100% !important;
  height: auto !important;
  background: transparent !important;
  border: 0;
  line-height: 45px;
  margin: 0;
  border-bottom: dashed 1px #d9d9d9;
  top: 0 !important;
}

.paymentBox .control__indicator.border-bottom-only {
  border: none;
  border-bottom: dashed 1px #d9d9d9;
  border-top: 0px !important;
}

.paymentBox .control input.pay-inpt:checked~.control__indicator .check-icon {
  background: #ec5e28;
}

.pay-btn-section {
  display: grid;
}

.paymentBox .control input.pay-inpt:checked~.control__indicator .pay-btn-section {
  display: grid;
}

.pay-btn-section .floating-label input {
  height: 48px;
  min-height: 0;
  box-sizing: border-box;
}

.pay-btn-section .floating-label input:focus,
.pay-btn-section .floating-label input:not(:placeholder-shown) {
  padding-top: 12px;
}

.pay-btn-section .floating-label label {
  font-size: 12px;
}

.pay-btn-section .checkbox-c label {
  margin-top: -2px;
}

.pay-btn-section .floating-label input:focus~label,
.pay-btn-section .floating-label input:not(:placeholder-shown)~label {
  top: 12px;
}

.info-label .floating-label input:focus~label,
.info-label .floating-label input:not(:placeholder-shown)~label {
  top: 16px;
}

.chk-footer {
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.04) !important;
  background: #f7f7f7;
}

.paymentBox .control__indicator.border-b {
  border: none;
  border-bottom: dashed 1px #e5e7eb !important;
}

.innerscroll {
  max-height: 400px;
  padding-bottom: 7rem;
}

.info-text {
  color: #1565c0;
}

.bg-success {
  background: #008945;
}

.bg-warning {
  background: #ec5e28;
}

.bg-error {
  background: rgba(224, 0, 0, 0.08);
}

.max-width-250 {
  max-width: 250px;
}

/* custom checkbox */
.custom-checkbox {
  position: absolute;
  opacity: 0;
}

.custom-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.custom-checkbox+label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #000;
}

.custom-checkbox:checked+label:before {
  background: #ec5e28;
  border: 1px solid #fff;
}

.custom-checkbox:checked+label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

/* end */

@media screen and (min-width: 1240px) and (max-width: 1680px) {
  .mobile-banner {
    height: auto !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    min-width: 90% !important;
  }
}

@media screen and (min-width: 1680px) {
  .mobile-banner {
    height: auto !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    /* min-width: 1796px !important; */
  }
}

@media screen and (max-width: 767px) {
  .mobile-banner {
    aspect-ratio: 3 / 4;
    object-fit: cover;
    object-position: center;
  }

  .pdp-color-variant {
    height: 90px !important;
    margin: 0px !important;
    padding: 0px !important;
    width: 3.5rem;
  }

  .pdp-color-variant img {
    width: 56px !important;
    height: 88px !important;
  }

  .ratings span {
    width: 20px !important;
    max-width: 20px !important;
    height: 20px !important;
    max-height: 20px !important;
  }

  .text-xxxs {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 380px) and (max-width: 520px) {
  .mobile-banner {
    aspect-ratio: 3 / 4;
    object-fit: cover;
    object-position: center;
  }
}

.inset-0 {
  z-index: 55;
}

.bg-cart-sidebar-green-light {
  background-color: #ebf6f1;
}

.text-green-dark {
  color: #00890e;
}

.basket-image {
  width: 78px !important;
  height: 128px !important;
  object-fit: cover;
  object-position: top center !important;
}

/* plp ui fix */
@media screen and (min-width: 1025px) {
  .sm\:hover\:scale-102:hover {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.h-auto {
  height: auto !important;
}

/* plp ui fix */
.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-docker::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.video-content {
  z-index: 2;
}

@media screen and (min-width: 640px) {
  .sm\:max-w-md {
    max-width: 34.5rem !important;
  }
}

@media screen and (max-width: 640px) {

  .m-hide-navigation .swiper-button-prev,
  .m-hide-navigation .swiper-button-next {
    display: none;
  }
}

.radius-xs {
  border-radius: 2px !important;
}

.description>p>span {
  background-color: transparent !important;
  color: inherit !important;
}

@media screen and (max-width: 767px) {
  .home-category span .image {
    width: 70px !important;
    height: 70px !important;
  }

  .text-badge-small {
    font-size: 9px !important;
  }

  .text-clip-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media screen and (min-width: 1025px) {
  .chk-icon-div {
    width: 40px;
    height: 40px;
  }

  .chk-left-border-div {
    position: absolute;
    border-left: 1px dashed #000;
    top: 35px;
    left: -13px;
    bottom: -57px;
  }

  .section-devider.margin-20 {
    margin: 20px 0;
  }

  .min-height-40 {
    min-height: 40px;
  }

  .add-list-div:nth-child(odd) {
    margin-right: 10px;
  }

  .add-list-div:nth-child(even) {
    margin-left: 10px;
  }

  .max-width-400 {
    max-width: 400px;
  }

  .text-xs-small {
    font-size: 5px;
    margin: 0 5px;
  }

  .opacity-full {
    opacity: 1 !important;
  }

  .filter-invert {
    filter: brightness(0) invert(1);
  }

  .z-100 {
    z-index: 100;
  }
}

/* checkout desktop UI css */
.z-100 {
  z-index: 100;
}

@media screen and (min-width: 1025px) {
  .innerscroll {
    max-height: 100vh;
    padding-bottom: 11rem;
    overflow-y: auto;
  }

  .pay-btn-section.net-btn {
    display: none !important;
  }
}

.rating {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.rating>label {
  color: #dbdbdb;
  display: inline-block;
}

.rating>label:before {
  content: url(https://img.damensch.com/icons/star-blank.svg);
  display: inline-block;
}

.rating>input {
  display: none;
}

.rating>input:checked~label,
.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label:before {
  color: #ec5e28;
  content: url(https://img.damensch.com/icons/star-fill.svg);
  display: inline-block;
}

.rating>input:checked+label:hover,
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
.rating>input:checked~label:hover~label {
  color: #ec5e28;
  content: url(https://img.damensch.com/icons/star-fill.svg);
  display: inline-block;
}

.rating-custom-check {
  display: none;
}

.rating-custom-check+.rating-custom-check-label {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  color: #000;
  padding: 5px 20px;
}

.rating-custom-check:checked+.rating-custom-check-label {
  background: rgba(235, 91, 37, 0.16);
  border: 1.5px solid #eb5b25;
  color: #eb5b25;
}

/* checkout desktop UI css */

@media screen and (min-width: 1580px) {
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1240px) and (max-width: 1579px) {
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
}

.care-p:last-child span {
  display: none;
}

.comma:last-child .s-icon {
  opacity: 0 !important;
}

.min-w-group {
  width: auto;
  padding-right: 5px;
  font-size: 12px !important;
  white-space: pre;
}

.mobile-h-scroll::-webkit-scrollbar {
  height: 0px;
}

.mobile-h-scroll::-webkit-scrollbar-track {
  background: #fff;
  opacity: 1;
}

.mobile-h-scroll::-webkit-scrollbar-thumb {
  background: #d3d3d3fb !important;
  height: 0px;
  border-radius: 6px;
}

.seo-info h3 {
  font-size: 0.875rem !important;
  font-weight: 600;
  margin-bottom: 15px !important;
  color: rgba(106, 52, 31, 0.4) !important;
}

.seo-info p {
  font-size: 12px !important;
  font-weight: 400;
  margin-bottom: 15px !important;
}

/* my account css start */
.main-custom-check {
  display: none;
}

.main-custom-check+.main-custom-check-label {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  color: #000;
  padding: 11px 15px;
}

.main-custom-check:checked+.main-custom-check-label {
  background: transparent;
  border: 1.5px solid #000;
}

.min-h-50 {
  min-height: 50px;
}

.max-acc-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

/* My account css */
.size-ul li:last-child span {
  display: none;
}

.tab-swiper .swiper-button-next,
.tab-swiper .swiper-button-prev {
  top: 31px !important;
}

.tab-swiper .swiper-button-next::before,
.tab-swiper .swiper-button-prev::before {
  height: 40px !important;
  top: -5px !important;
}

.tab-swiper .swiper-slide {
  border: 1px solid #898989 !important;
  background-color: #f3f3f3 !important;
}

.tab-swiper .swiper-slide:hover {
  border: 1px solid #000000 !important;
  background-color: #f5f5f5 !important;
}

.new-tab-swiper .swiper-slide {
  border: 1px solid #e9e5df !important;
  border-radius: 8px;
}

.new-tab-swiper .swiper-slide:hover {
  border: 1px solid #e9e5df !important;
  border-radius: 8px;
}

/* plp image slider dot ui */
.imae-container .swiper-pagination-bullet {
  width: 4px !important;
  height: 4px !important;
  background: #c4c4c4 !important;
  opacity: 0;
}

.main-card-hover:hover .imae-container .swiper-pagination-bullet {
  opacity: 1;
}

.imae-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ec5e28 !important;
}

.imae-container {
  background: #fff;
}

.main-card-hover:hover .imae-container .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
  background: #fff;
}

@media screen and (max-width: 767px) {

  .main-card-hover:hover .imae-container .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal,
  .main-card-hover .imae-container .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px !important;
    background: transparent;
  }

  .imae-container .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
    padding-bottom: 24px !important;
  }

  .imae-container .swiper.swiper-initialized.swiper-horizontal.swiper-ios {
    padding-bottom: 24px !important;
  }

  .imae-container .swiper-pagination-bullet {
    opacity: 1 !important;
  }
}

.scroll-active {
  top: 107px;
  left: 0;
  right: 0;
  margin: 0 -24px;
  --tw-shadow: 0 3px 5px -3px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.scroll-active .sm\:col-span-4,
.scroll-active .sm\:col-span-8 {
  padding-left: 24px;
}

.scroll-cover-shadow {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  height: 5px;
  z-index: 9;
}

/* inline filter plp UI End*/

/* order history css start */
.order-image-sec .order-image-nonslider {
  margin-right: 10px;
}

.order-image-sec .order-image-nonslider:last-child {
  margin-right: 0;
}

.acc-btn-sec a.link-btn {
  /* max-width: 300px; */
  width: 100%;
}

.max-rating-container {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.textarea-input {
  min-height: 56px;
  background: rgba(219, 219, 219, 0.32);
  border-radius: 2px;
  min-height: 110px;
}

#myfile {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}

#myfile+label {
  background: rgba(219, 219, 219, 0.32);
  border-radius: 2px;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
}

/* order history css End */

/* order detail css start */
.o-detail-continer {
  max-width: 550px;
  width: 100%;
  padding-left: 4rem;
}

.font-10 {
  font-size: 10px;
}

.section-devider.m-0 {
  margin: 0;
}

.label-confirmed {
  background: rgba(0, 137, 14, 0.12);
  border-radius: 2px;
  color: #00890e;
  line-height: 1;
}

.label-Cancelled {
  background: rgba(193, 0, 0, 0.12);
  border-radius: 2px;
  color: #e00000;
  line-height: 1;
}

.label-pending {
  background: rgba(255, 152, 0, 0.12);
  border-radius: 2px;
  color: #ff9800;
  line-height: 1;
}

.order-track {
  /* padding-top: 20px; */
  display: flex;
  flex-direction: column;
}

.order-track-step {
  display: flex;
  height: 50px;
}

.order-track-step:last-child {
  overflow: hidden;
  /* height: 4rem; */
}

.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}

.order-track-step.completed:last-child .order-track-status-line,
.order-track-step.cancelled:last-child .order-track-status-line {
  display: none !important;
}

.order-track-step.completed:last-child .check-s-icon,
.order-track-step.cancelled:last-child .check-s-icon {
  display: inline-block !important;
}

.order-track-status {
  margin-right: 1rem;
  position: relative;
}

.order-track-status-dot {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f3f4f5;
  border: 1px solid #eb5b25;
  align-items: center;
  justify-content: center;
}

.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 1px;
  height: 50px;
  background: #fff;
  border-left: 1px dotted #000;
}

.order-track-text-stat {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.6;
}

.order-track-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.order-track-step.completed .order-track-status-dot {
  background: #00890e;
  border: 1px solid #00890e;
}

.check-s-icon {
  display: inline;
}

.order-track-step.completed .order-track-text-stat {
  opacity: 1;
}

.order-track-step.completed .check-s-icon {
  display: inline;
}

.order-track-step.cancelled .order-track-status-dot {
  background: #c10000;
  border: 1px solid #c10000;
}

.order-track-step.cancelled .order-track-text-stat {
  opacity: 1;
}

.order-track-step.cancelled .check-s-icon {
  display: inline;
}

/* order detail css End */

/* cancel ui css */
.cancel-continer {
  max-width: 520px;
  width: 100%;
  /* padding: 30px; */
  margin: 0 auto;
}

/* cancel ui css */
.right-10.r-l-32 {
  right: 31px;
}

@media screen and (max-width: 767px) {
  .mob-scroll-sec {
    max-height: 90vh;
    overflow-y: scroll;
  }
}

.hover\:menu-hover:hover {
  background-color: rgb(245, 246, 245) !important;
}

/* paymetn section ui start */
.acc-hidden {
  display: none !important;
}

/* paymetn section ui end */

/* cms page css start */
.max-600-w {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.tab-active .cms-non-hover-icon {
  display: none;
}

.tab-active .cms-hover-icon {
  display: inline-block;
}

.max-800-w {
  max-width: 800px;
}

.max-1024-w {
  max-width: 1024px;
}

@media screen and (max-width: 767px) {

  /* about us responsive css start */
  .image-about-banner img {
    height: 500px;
    object-fit: cover;
    object-position: center center;
  }

  /* about us responsive css start */
}

.swiper-slide img.couponIcon {
  width: 40px;
}

/* cms page css end */

.coupon-text-rotate {
  width: 140px;
  position: absolute;
  left: -44px;
  top: 44%;
  rotate: -90deg;
  text-align: center;
  font-size: 11px !important;
  line-height: 11px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  max-width: 120px;
}

.coupon-cross {
  background-color: #ec5e28 !important;
}

.green-coupon-cross {
  background-color: #008945 !important;
}

.coupon-cross:after {
  content: '';
  background-image: url(https://img.damensch.com/icons/coupon-design.svg);
  height: 100%;
  width: 10px;
  right: -3px;
  top: 0;
  z-index: 9;
  position: absolute;
  rotate: 0deg;
  background-repeat: no-repeat;
  background-repeat: repeat-y;
}

.green-coupon-cross:after {
  content: '';
  background-image: url(https://img.damensch.com/icons/green-coupon-strip.svg);
  height: 100%;
  width: 10px;
  right: -3px;
  top: 0;
  z-index: 9;
  position: absolute;
  rotate: 0deg;
  background-repeat: no-repeat;
  background-repeat: repeat-y;
}

.coupon-cross-gray {
  background-color: #999999 !important;
  color: #999999 !important;
}

.coupon-cross-gray:after {
  content: '';
  background-image: url(https://img.damensch.com/icons/coupon-design-gray.svg);
  height: 100%;
  width: 10px;
  right: -3px;
  top: 0;
  z-index: 9;
  position: absolute;
  rotate: 0deg;
  background-repeat: no-repeat;
  background-repeat: repeat-y;
}

.coupon-cross-success {
  background-color: #00890e !important;
  color: #ffffff !important;
}

.coupon-cross-success:after {
  content: url(https://img.damensch.com/icons/coupon-design-success.svg);
  height: 100%;
  width: 10px;
  right: -29px;
  top: 3px;
  z-index: 9;
  position: relative;
  rotate: -90deg;
}

@media screen and (max-width: 1620px) {
  .small-pt-52 {
    padding-top: 4rem !important;
  }
}

.center-fix-panel {
  position: relative;
  left: 0%;
  top: 35% !important;
}

.icon-bg-none .swiper-button-next::before,
.icon-bg-none .swiper-button-next:after,
.icon-bg-none .swiper-button-prev:after {
  background: transparent;
}

.coupon-code-panel {
  width: 37px !important;
}

.coupon-code-data {
  width: calc(100% - 0px) !important;
  padding-left: 40px !important;
}

@media screen and (max-width: 767px) {
  .center-fix-panel {
    position: fixed;
    left: 0%;
    top: unset !important;
    bottom: 0 !important;
  }

  .swiper-slide img.couponIcon {
    width: 22px;
  }

  .mobile-padding-quick-view .quick-view-padding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.cokkie-msg-sec {
  max-width: 350px;
  width: 100%;
  display: none;
  flex-direction: column;
  background: #000;
  position: fixed;
  bottom: 0px;
  left: 20px;
  z-index: 99999;
  padding: 20px;
  margin-bottom: 20px;
}

.cokkie-msg-sec button {
  background-color: #fff;
  color: #000;
  margin-top: 20px;
  margin: 10px 0 0 0;
  padding: 10px 20px;
}

.fh-24 {
  height: 20px;
}

.fh-28 {
  height: 25px;
}

.fh-40 {
  height: 40px;
}

.fh-48 {
  height: 48px;
}

.max-width-150 {
  max-width: 140px;
}

.hf-138 {
  min-height: 132px;
}

.hf-28px {
  height: 28px;
}

.hf-23px {
  height: 23px;
}

.hf-48px {
  height: 48px;
}

.hf-40px {
  height: 40px;
}

.mr-05 {
  margin-right: 0.125rem;
}

.ml-05 {
  margin-left: 0.125rem;
}

@media screen and (max-width: 767px) {
  .cokkie-msg-sec {
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }

  .max-width-150 {
    max-width: 100px;
  }

  /* my account mobile css start */
  .main-account-grid .col-span-3.mob-tab-full,
  .main-account-grid .col-span-9.mob-tab-full {
    grid-column: span 12 / span 12;
    border: none;
  }

  .col-span-9.mob-tab-full {
    grid-column: span 12 / span 12;
    border: none;
  }

  .acc-mob-icon-i {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .opacity-full {
    opacity: 1 !important;
  }

  /* .opacity-half {
    opacity: 0.5 !important;
  } */

  .mob-hidden {
    display: none;
  }

  .related-products-swiper-wrapper {
    position: absolute;
    visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
    transition: 0.5s;
    top: 0px;
    right: 0;
    left: 0;
    padding: 8px 0;
    background: white;
    z-index: 0;
  }

  .related-products-swiper-wrapper.active {
    position: fixed;
    visibility: visible;
    top: 38px;
    right: 0;
    left: 0;
    z-index: 10;
    transform: translateY(0);
    opacity: 1;
  }

  .col-span-9.mob-tab-full {
    padding-top: 0;
  }

  .o-detail-continer {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* my account mobile css End */
}

.cursor-zoom {
  cursor: zoom-in !important;
}

.custom-html h3 {
  margin-top: 20px !important;
}

.custom-html p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 767px) {
  .bg-selected-facet {
    background-color: rgba(236, 94, 40, 0.08);
    padding: 8px 15px;
  }

  .bg-selected-sort {
    background-color: rgba(236, 94, 40, 0.08);
  }

  .bg-nonselected-facet {
    background-color: transparent;
    padding: 5px 15px;
  }

  .max-panel-mobile-filter {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 400px;
  }
}

.collection-multi-vimage,
.collection-multi-vimage span {
  /* width: auto !important; */
  /* height: auto !important; */
  width: 100% !important;
  height: 100% !important;
}

.collection-multi-vimage img {
  /* width: auto !important; */
  /* height: auto !important; */
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 1580px) {

  .collection-multi-vimage,
  .collection-multi-vimage span {
    min-height: 222px !important;
  }

  .collection-multi-vimage img {
    /* width: auto !important;
    height: auto !important; */
    width: 100% !important;
    height: 100% !important;
  }
}

.category-swiper .swiper-button-next,
.category-swiper .swiper-button-prev {
  top: 79% !important;
}

.category-banner-swiper .swiper-button-next,
.category-banner-swiper .swiper-button-prev {
  top: 55% !important;
}

@media screen and (max-width: 767px) {
  .mobile-filter-form>form {
    width: 60%;
    display: inline-block;
    padding-top: 7px !important;
    border-top: 1px solid rgba(229, 231, 235, 1) !important;
  }
}

.divide-gray-200> :not([hidden])~ :not([hidden]),
.border-gray-200 {
  --tw-divide-opacity: 1;
  border-color: #e9e7e5;
}

@media (min-width: 640px) {
  .right-13 {
    right: 13px;
  }
}

.bg-body-gray {
  background: #f7f7f7;
}

.select-eapand-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sprite-bg-select {
  position: absolute;
  right: 23px;
  pointer-events: none;
}

.promotion-sticky .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #EB5B25;
}

@media screen and (max-width: 767px) {
  .bottom-to-top .translate-x-full {
    transform: translateY(100%) !important;
  }

  .bottom-to-top .translate-x-0 {
    transform: translateY(0%) !important;
  }

  .mobile-position {
    height: calc(100% - 80px) !important;
    position: fixed;
    bottom: 0%;
  }

  .cvv-mobile-position {
    height: calc(100% - 280px) !important;
    position: fixed;
    bottom: 0%;
  }

  .cvv-mobile-position-plp {
    height: auto !important;
    position: fixed;
    bottom: 0%;
  }

  .upi-mobile-position {
    height: calc(100% - 250px) !important;
    position: fixed;
    bottom: 0%;
  }

  .confirmation-back-position {
    height: 245px !important;
    position: fixed;
    bottom: 0%;
  }

  .confirmation-pod-position {
    height: 290px !important;
    position: fixed;
    bottom: 0%;
  }

  .rbi-mobile-position {
    height: calc(100% - 300px) !important;
    position: fixed;
    bottom: 0%;
  }
}

.sizeChart [type='radio']:not(:checked) {
  position: relative !important;
  left: 0 !important;
}

.switch-panel {
  width: 40px;
  height: 32px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  border: 2px solid #000000;
  font-weight: 600;
}

.switch-panel-parent {
  width: 80px;
  height: 34px;
  line-height: 32px;
  font-size: 12px;
  border: 1px solid #dddddd;
}

.switch-cm-before::after {
  content: 'cm';
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 12px;
  font-weight: 500;
  z-index: 9;
  color: #000000;
}

.switch-in-before::after {
  content: 'in';
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 12px;
  font-weight: 500;
  z-index: 9;
  color: #000000;
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .col-span-3.tab-list-sm {
    grid-column: span 4 / span 4;
  }

  .col-span-9.tabpanel-sm {
    grid-column: span 8 / span 8;
  }

  .col-span-9.tabpanel-sm .w-3\/5 {
    width: 100%;
  }

  .save-upi-s {
    padding-left: 8px !important;
  }
}

.order-link .cat-span:last-child span.comma-span {
  display: none;
}

.order-link .cat-span:last-child .arrow-span {
  display: inline;
}

.mobile-swiper-zoom .swiper-slide {
  width: 100% !important;
  display: block !important;
}

.accunt-main-container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

.flex-grow-1 {
  flex-grow: 0 !important;
}

.flex-margin {
  margin-bottom: 0;
  margin-top: auto;
}

.innerscroll::-webkit-scrollbar {
  width: 8px;
}

.innerscroll::-webkit-scrollbar-track {
  background: #fff;
  opacity: 1;
}

.innerscroll::-webkit-scrollbar-thumb {
  background: #d3d3d3fb !important;
  width: 8px;
  border-radius: 0px;
}

@media screen and (max-width: 767px) {
  .bg-body-gray {
    background: #ffffff !important;
  }

  .pay-btn-section {
    display: none;
  }

  .paymentBox .control__indicator {
    border: 0px solid #dddddd !important;
  }

  .method-order {
    border-bottom: 1px dashed #d9d9d9 !important;
  }

  .method-order:last-child {
    border-bottom: 0px dashed #d9d9d9 !important;
  }
}

.pointer-event-none {
  pointer-events: none !important;
  cursor: none !important;
}

.flex-container {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 20px;
}

@media screen and (max-width: 767px) {
  .m-leading-none {
    line-height: 1;
  }

  .flex-container {
    padding: 30px 20px;
  }

  .mob-order .floating-label:nth-child(1) {
    order: 2;
  }

  .mob-order .floating-label:nth-child(2) {
    order: 1;
  }

  .mob-order .floating-label:nth-child(3) {
    order: 3;
  }

  .mob-order .floating-label:nth-child(4) {
    order: 4;
  }

  .sticky-w-header {
    margin: 0 -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .full-m-header {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .full-m-ex-header {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .full-m-pro-header {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 45px;
    padding-right: 45px;
  }

  .mob-p-170 {
    padding-bottom: 100px;
    position: relative;
    z-index: 12;
    background-color: #ffffff;
  }

  .mob-p-170>form {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
  }

  .m-92-img {
    height: 92px;
  }

  .bg-selected-address .span-bg {
    background: #ec5e28;
  }

  .bg-selected-address .hidden.span-tick-icon {
    display: inline;
  }
}

.z-9999 {
  z-index: 99 !important;
}

.error-modal {
  background: #ff0000;
  border: 0px;
  top: 0px;
  color: #fff;
  text-align: center;
  z-index: 999999;
}

.Modal_root__4gN_y {
  justify-content: baseline !important;
}

.card_number_div .card_number {
  padding: 0 20px !important;
}

.custom-html>div {
  background-color: transparent !important;
  color: #251000 !important;
  border: 0px !important;
}

.custom-html>div h2 {
  font-size: 12px !important;
  font-family: 'Inter' !important;
  font-weight: 600 !important;
  color: #251000 !important;
}

.custom-html>div p {
  font-size: 10px !important;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  color: #251000 !important;
  opacity: 0.52 !important;
}

.bg-ribbon-green {
  background-color: #00890e !important;
}

.bg-ribbon-red {
  background-color: #c10000 !important;
}

.bg-ribbon-cancel {
  background-color: #fdebeb !important;
}

@media screen and (min-width: 1025px) {
  .height-react {
    height: 617px !important;
    background-color: #000;
  }

  .height-react.v-height-vid {
    height: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .m-justify-center {
    justify-content: center;
  }

  .min-product-info.active {
    position: fixed;
    padding: 0px !important;
    display: flex;
    background-color: #ffffff !important;
    z-index: 49;
    border-top: 1px solid #dddddd !important;
  }

  .min-product-info-plp.active {
    position: fixed;
    padding: 0px !important;
    display: flex;
    background-color: #ffffff !important;
    z-index: 49;
    border-top: 1px solid #dddddd !important;
  }
}

input[type='radio']:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f3f3f3;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid rgba(0, 19, 37, 0.16);
}

input[type='radio'] {
  position: relative;
}

input[type='radio']:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec5e28;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid #ec5e28;
}

input[type='radio']:checked::before {
  content: '';
  float: right !important;
  right: 1px !important;
  top: 2px !important;
  z-index: 99999;
  position: absolute !important;
  width: 6px !important;
  height: 10px !important;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

.juspay_locker_save:after {
  width: 20px;
  height: 20px;
  border-radius: 0px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #f3f3f3;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid rgba(0, 19, 37, 0.16);
}

.juspay_locker_save {
  position: relative;
  margin-right: 14px;
}

.juspay_locker_save:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 0px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #ec5e28;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid #ec5e28;
}

.juspay_locker_save:checked::before {
  content: '';
  float: right !important;
  right: 1px !important;
  top: 3px !important;
  z-index: 99999;
  position: absolute !important;
  width: 6px !important;
  height: 10px !important;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

input[type='radio']:checked~td {
  background-color: #00890e !important;
}

input[type='radio']:checked~td::after {
  content: '';
  background-color: #00890e !important;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #fff;
  opacity: 1;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #d3d3d3fb !important;
  width: 6px;
  border-radius: 0px;
}

.promo-copied {
  top: -30px;
  right: -20px;
  position: absolute;
  float: right;
  margin-right: 0;
  width: 90px;
  z-index: 99;
}

.promo-copied:after {
  content: '>';
  font-size: 35px;
  color: #000;
  position: absolute;
  bottom: -4px;
  right: 26%;
  z-index: -1;
  transform: rotate(90deg);
}

.promo-copied-new {
  width: 100px;
}

.nav-container {
  max-width: 1200px;
  width: 100%;
}

.hide-swatch-quickview .hide-swach-padding {
  padding: 0px !important;
}

.btn-basic-property {
  height: 48px !important;
  line-height: 48px !important;
  font-size: 14px !important;
}

.btn-basic-property-sm {
  height: 32px !important;
  line-height: 32px !important;
  font-size: 12px !important;
}

.btn-basic-property-big {
  height: 56px !important;
  line-height: 56px !important;
  font-size: 14px !important;
}

.btn-basic-property-bigfaq {
  height: 42px !important;
  line-height: 56px !important;
  font-size: 14px !important;
}

.min-width-100 {
  max-width: 80px !important;
}

.acc-sec-upi .save-upi-s {
  padding-right: 70px;
}

.sticky-top-info {
  top: 6.75rem !important;
  z-index: 49;
}

.plp-video-banner {
  padding: 10px 35px;
}

.height-react-plp {
  height: 224px !important;
  background-color: #000;
}

@media screen and (max-width: 767px) {
  .plp-video-banner {
    padding: 10px 0;
  }

  .sticky-top-info {
    top: 3.75rem !important;
    z-index: 49;
  }
}

.blog-detail p {
  color: #251000;
  opacity: 0.9;
  font-size: 12px !important;
  font-weight: 400;
}

.blog-detail h2 {
  color: #251000;
  opacity: 1;
  font-size: 20px !important;
  font-weight: 600;
  margin: 15px 0px;
}

.blog-detail h3 {
  color: #251000;
  opacity: 1;
  font-size: 18px !important;
  font-weight: 600;
  margin: 15px 0px;
}

.blog-detail ul {
  color: #251000;
  opacity: 0.9;
  padding-left: 30px !important;
  font-size: 12px !important;
  font-weight: 400;
  list-style: disc;
}

.blog-detail img {
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.blog-detail a {
  text-decoration: underline;
  color: #5469d4 !important;
}

.grid .nav-inner-li:last-child .navlink-inner {
  border: 0;
}

.leading-none.line-height-0 {
  line-height: 0;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-410 {
  min-width: 410px !important;
}

.padding-search-result {
  padding: 24px 40px !important;
}

.top-90 {
  top: 2%;
}

.v-image-space {
  padding: 10px 14px;
}

@media screen and (max-width: 767px) {
  .mob-width-full {
    width: 100%;
  }

  .height-react.m-height-100 {
    height: 430px !important;
    background: #fff !important;
  }
  .v-height-vid {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .height-react.m-height-100.v-height-vid {
    height: 530px !important;
  }

  .height-react.m-height-100.v-quickView {
    height: auto !important;
  }

  .v-image-space {
    padding: 0px 0px;
  }

  .collection-multi-vimage,
  .collection-multi-vimage span {
    min-height: 0 !important;
    height: auto !important;
  }

  .collection-multi-vimage img {
    height: auto !important;
    min-height: 0 !important;
    object-fit: cover !important;
    object-position: top !important;
  }

  .top-90 {
    top: 7%;
    width: 80px !important;
  }

  .min-w-410 {
    min-width: 100% !important;
  }

  .padding-search-result {
    padding: 12px 1.5rem 12px 20px !important;
  }
}

.f-label-name::first-letter {
  text-transform: capitalize;
}

.category-filter-swiper .swiper-button-next,
.category-filter-swiper .swiper-button-prev {
  top: 75% !important;
}

.category-filter-swiper.cs-mr-1 {
  margin-right: -1rem;
}

.category-filter-swiper .cs-sm-thumbnail img {
  width: auto;
  max-width: 36px;
  height: 36px;
}

.category-filter-swiper .swiper-button-next::before {
  content: '';
  background: linear-gradient(270deg,
      #ffffff 33.28%,
      rgba(255, 255, 255, 0) 100%) !important;
  height: 50px;
  width: 60px !important;
  position: absolute;
  right: -40px !important;
  top: -35px !important;
  z-index: -2;
}

/* Overlay Loader */
.overlay-panel {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.search-result {
  overflow: auto;
}

.search-result::-webkit-scrollbar {
  display: none;
}

.searchbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .search-top {
    top: 3.8rem;
  }

  .search-list-image {
    height: 64px;
    width: 40px;
  }
}

.search-contain::-webkit-scrollbar {
  width: 0;
  position: relative;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
}

@media screen and (min-width: 639px) {
  .searchbar-wrapper {
    position: relative;

    .view-all {
      text-align: center;
      display: block;
      cursor: pointer;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.14);
    }
  }

  .search-image {
    height: 272px;
    width: 153px;
  }

  .search-wrapper {
    right: 0px;
    top: -15px !important;
  }
}

@media screen and (min-width: 768px) {
  .category-filter-swiper .cs-sm-thumbnail img {
    padding: 4px;
  }

  .category-filter-swiper.cs-mr-1 {
    margin-right: auto;
  }

  .popover-overlay {
    position: absolute;
    background: rgba(106, 52, 31, 0.4);
    display: block;
    width: 100%;
    height: 100vh;
    /* pointer-events: none; */
  }

  .width-50 {
    width: 50px !important;
  }

  .search-min-width-450 {
    width: 450px;
    max-width: 450px;
    z-index: 99;
    background: linear-gradient(270deg,
        #ffffff 96.21%,
        rgba(255, 255, 255, 0) 100%);
  }

  .search-min-width-410 {
    width: 410px;
    min-width: 410px;
    z-index: 99;
  }
}

.category-filter-swiper .swiper-button-next,
.category-filter-swiper .swiper-rtl .swiper-button-prev,
.category-filter-swiper .swiper-button-prev,
.category-filter-swiper .swiper-rtl .swiper-button-next {
  background-color: #fff !important;
}

.custom-checkbox-promo {
  position: relative !important;
}

.custom-checkbox-promo:after {
  /* width: 20px;
  height: 20px; */
  width: 22px;
  height: 22px;
  border-radius: 20px;
  top: -4px;
  left: -3px;
  position: relative;
  background-color: #f3f3f3;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid rgba(0, 19, 37, 0.16);
}

.custom-checkbox-promo:checked:after {
  /* width: 20px;
  height: 20px; */
  width: 22px;
  height: 22px;
  border-radius: 20px;
  top: -4px;
  left: -3px;
  position: relative;
  background-color: #ec5e28;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid #ec5e28;
}

.custom-checkbox-promo:checked::before {
  content: '';
  /* float: right !important;
  right: 3px !important;
  top: 0px !important; */
  z-index: 99999;
  position: absolute !important;
  width: 6px !important;
  height: 10px !important;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  /* transform: rotate(45deg) !important; */
  top: 50%;
  left: 25%;
  transform: rotate(45deg) translate(-50%, -50%);
}

.height-react.height-100 {
  height: 100% !important;
  background-color: #000;
}

.video-image-grid .image-container.v-container-image:last-child {
  order: 2 !important;
}

.video-image-grid .image-container.v-container-image:nth-child(1) {
  order: 1;
}

.video-image-grid .image-container.v-container-image:nth-child(2) {
  order: 3;
}

.video-image-grid .image-container.v-container-image:nth-child(3) {
  order: 4;
}

.video-image-grid .image-container.v-container-image:nth-child(4) {
  order: 5;
}

.video-image-grid .image-container.v-container-image:nth-child(5) {
  order: 6;
}

.video-image-grid .image-container.v-container-image:nth-child(6) {
  order: 7;
}

.video-image-grid .image-container.v-container-image:nth-child(7) {
  order: 8;
}

.video-image-grid .image-container.v-container-image:nth-child(8) {
  order: 9;
}

.video-image-grid .image-container.v-container-image:nth-child(9) {
  order: 10;
}

.video-image-grid .image-container.v-container-image:nth-child(10) {
  order: 11;
}

.video-image-grid .image-container.v-container-image:nth-child(11) {
  order: 12;
}

.video-image-grid .image-container.v-container-image:nth-child(12) {
  order: 13;
}

.video-image-grid .image-container.v-container-image:nth-child(13) {
  order: 14;
}

.video-image-grid .image-container.v-container-image:nth-child(14) {
  order: 15;
}

.video-image-grid .image-container.v-container-image:nth-child(15) {
  order: 16;
}

.video-image-grid .image-container.v-container-image:nth-child(17) {
  order: 18;
}

.video-image-grid .image-container.v-container-image:nth-child(18) {
  order: 19;
}

.video-image-grid .image-container.v-container-image:nth-child(19) {
  order: 20;
}

.video-image-grid .image-container.v-container-image:nth-child(20) {
  order: 21;
}

.video-image-grid .image-container.v-container-image:nth-child(21) {
  order: 22;
}

.offer-list-div {
  height: 18px;
  overflow: hidden;
}

.height-full {
  height: auto !important;
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .padding-x-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 768px) {
  .bg-des-offer {
    background: rgba(255, 255, 255, 0.4) !important;
    border: 1px solid rgba(37, 16, 0, 0.16) !important;
  }
}

.card_number {
  padding-left: 10px !important;
}

.h-d-50 {
  height: 50px;
}

.w-d-50 {
  width: 50px;
}

.w-auto {
  width: auto !important;
}

@media screen and (max-width: 768px) {
  .modal-nav-open {
    overflow: hidden !important;
  }

  .mob-alignment-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.error-container {
  max-width: 660px;
  width: 100%;
  margin: 0 auto;
}

.quick-attr-d .font-clr-quick {
  color: rgba(37, 16, 0, 0.56) !important;
}

.quick-wishlist-border {
  border: 1px solid rgba(0, 0, 0, 0.14) !important;
  border-radius: 2px 0px 0px 2px !important;
}

.h-full.h-per-full {
  height: 100vh !important;
  min-height: 100vh !important;
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 768px) {
  .width-cart {
    max-width: 470px !important;
    width: 470px !important;
  }

  .cart-related-prod .p-h-16r {
    height: 300px !important;
  }

  .cart-related-prod .product-ratio-image {
    height: 300px !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 640px) {
  .cart-related-prod .p-h-16r {
    height: 320px !important;
  }
}

.profile-container {
  max-width: 640px !important;
  width: 100%;
  padding: 0 20px;
}

.max-w-mob {
  max-width: 450px;
}

@media screen and (max-width: 767px) {
  .mob-trun-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    display: inline-block;
    vertical-align: middle;
  }

  .mob-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    grid-column: span 10 / span 10;
  }

  .quick-attr-d.mob-slide-icon-hide .icon-hide-mob .swiper-button-next,
  .quick-attr-d.mob-slide-icon-hide .icon-hide-mob .swiper-button-prev,
  .icon-hide-mob .swiper-button-next,
  .icon-hide-mob .swiper-button-prev {
    display: none;
  }

  .gap-y-4.mob-gap-y-4 {
    row-gap: 0.25rem;
  }

  .mob-promo-grid .mob-left-7 {
    grid-column: span 12 / span 12;
  }

  .mob-promo-grid .mob-left-5 {
    grid-column: span 10 / span 10;
    margin: 5px 0;
  }

  .mob-promo-grid .mob-left-5 .promo-copy-panel {
    position: relative;
    width: 100% !important;
  }

  .max-w-mob {
    max-width: 300px;
  }

  .profile-container {
    padding: 0;
  }

  .date-inpt::-webkit-date-and-time-value {
    text-align: left;
  }

  .add-form-sec .floating-label input {
    margin: 4.5px 0 !important;
  }

  .width-cart {
    max-width: 100% !important;
    width: 100vh !important;
  }

  .cart-related-prod .p-h-16r {
    height: 320px !important;
  }

  .cart-related-prod .product-ratio-image {
    height: 320px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.cart-related-prod .hidden-all-add {
  display: inline-block;
}

.cart-related-prod .hidden-cart-sec {
  display: none;
}

@media screen and (min-width: 768px) {
  .sm-top-auto {
    top: auto !important;
  }

  .profile-container input {
    font-size: 14px;
    color: #251000;
  }
}

.upi-text {
  padding-right: 65px;
}

.no-change-button .change-button {
  display: none !important;
}

.max-width-upi {
  max-width: 120px;
}

.flex-width-nob {
  overflow: hidden;
}

.pl-1.pl-d-1 {
  padding-left: 1px;
}

.promo-shows {
  top: -34px;
  right: -65px;
  position: absolute;
  float: right;
  margin-right: 0;
  width: 150px;
  z-index: 99;
  font-size: 9px;
}

.promo-shows:after {
  content: '>';
  font-size: 35px;
  color: #000;
  position: absolute;
  bottom: -4px;
  right: 39%;
  z-index: -1;
  transform: rotate(90deg);
}

.order_size {
  order: 2;
  display: flex;
  flex-direction: column;
}

.order_colour {
  order: 1;
  display: flex;
  flex-direction: column;
}

.cart-related-prod.max-prod-width .max-w-mob-150 {
  max-width: 150px !important;
}

.after-border {
  position: relative;
}

.after-border:after {
  position: absolute;
  content: '';
  top: -17px;
  bottom: 0;
  right: 0;
  height: 5rem;
  width: 1px;
  background: rgba(255, 255, 255, 0.16);
}

.empty-msg:empty {
  max-width: 300px;
  width: 100%;
  height: 25px;
  position: relative;
  margin: 0;
}

.empty-msg:empty:after {
  position: absolute;
  content: 'No image available';
  top: 0;
  left: 0;
  font-size: 14px;
  color: #251000;
}

.default-chck-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.juspay_locker_save.default-chck-none:checked::before {
  right: 6px !important;
}

.infinite-scroll-component {
  overflow-y: hidden !important;
}

.s-icon.s-right-space {
  padding-right: 5px;
}

.cart-related-prod .width-max--title {
  width: 200px;
}

@media screen and (max-width: 767px) {
  .mob-align-grid-top {
    align-items: flex-start;
  }

  .mob-img-container>span {
    width: 100% !important;
  }

  .cart-related-prod .width-max--title {
    width: 230px;
  }

  .m-colum-div {
    flex-direction: column;
  }

  .care-p.mob-full-p {
    width: 100%;
    padding: 5px 0;
  }

  #view-all-result-mobile {
    position: fixed;
    bottom: 22px;
    width: 88%;
    top: aut;
  }
}

.break-word-text {
  word-break: break-word;
}

.border-highlight {
  border: 1px solid #222222 !important;
}

.overlow-y-auto-p {
  overflow-y: auto !important;
}

.overlow-y-auto--notify[style='color-scheme: light; overflow: hidden;'] {
  overflow-y: auto !important;
}

.tab-img-group .cms-non-hover-icon,
.tab-img-group .cms-hover-icon {
  width: 100px;
}

@media screen and (max-width: 767px) {
  .promo-copied {
    top: -46px;
  }

  .promo-copied-new {
    top: -28px;
  }

  .min-w-group {
    padding: 0 0.3rem;
  }

  .min-w-50 {
    min-width: 45px !important;
  }

  .tab-img-group .cms-non-hover-icon,
  .tab-img-group .cms-hover-icon {
    width: 50px;
  }

  .tab-img-group h2 {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .mob-sticky-none {
    position: relative;
  }

  .mob-left-padding {
    padding-left: 10px;
  }

  .mob-padding-bottom {
    padding-bottom: 40px;
  }

  .btn-basic-property.cart-btn {
    height: 36px !important;
    line-height: 36px !important;
    font-size: 12px !important;
  }
}

.color-panel .border-highlight {
  border: 2.5px solid #222222 !important;
}

.about {
  position: relative;
}

.about h1 {
  font-size: 20pt;
  font-weight: bold;
  padding: 10px;
}

.about p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.about p br {
  display: none;
}

.ul-li-disc ul {
  padding-left: 20px;
  list-style: disc;
}

@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .f-info-img-text .info-img img {
    width: 50px !important;
    min-width: 0 !important;
  }

  .f-info-p {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1300px) {
  .f-info-img-text .info-img img {
    width: 80% !important;
    min-width: 0 !important;
  }
}

.text-md-scrn {
  line-height: 1 !important;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1320px) {
  .text-md-scrn {
    font-size: 11px !important;
  }
}

.offer-m-sec {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 6px 14px 6px 34px;
}

.opacity_056 {
  opacity: 0.56;
}

.opacity_092 {
  opacity: 0.92;
}

.cs-bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.sticky-product-button-wrapper {
  width: 100%;
}

.sticky-product-button-wrapper button {
  width: 100%;
  outline: none;
  border: none;
}

.opacity_02 {
  opacity: 0.2;
}

.pick-pay-go-wrapper .black-area-height {
  height: calc(100vh - 290px);
}

.csHideCamera {
  position: relative;
}

.csHideCamera:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background: #000;
  position: absolute;
  top: 0;
  bottom: 0;
}

.pick-pay-go-wrapper .custom-next-btn {
  position: fixed;
  /* left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem; */
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
}

.displayNone {
  display: none;
}

.camera-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #000;
  border: 1px solid rgb(243 243 243 / 70%);
}

.camera-image .generic-loading-dots {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding-top: 110px;
}

.camera-image svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.pick-pay-go-wrapper .custom-next-btn.chk-btn {
  position: static;
}

.pick-pay-go-wrapper .custom-next-btn.chk-btn button {
  /* background: linear-gradient(120.54deg, #eb5b25, rgba(255, 255, 255, 0.5), #eb5b25); */
  background: linear-gradient(120deg, #000 30.09%, #5c5c5c 35.38%, #000 40.22%);
  border: 1px solid transparent;
  background-size: 300% 300%;
  border-radius: 2px;
  animation: shiningAnimation 2s infinite 500ms;
}

.pick-pay-go-wrapper .pick-pay-go-skeleton-loader-wrapper::after {
  animation: pickPayGoAnimation 5s infinite;
  transform: translateX(-100%);
}

@keyframes pickPayGoAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.pick-pay-go-wrapper .pick-pay-go-skeleton-loader-wrapper .skeleton-loading {
  margin-bottom: 1em;
  border-radius: 6px;
  height: 1.5em;
  overflow: hidden;
  background: #e5e4e2;
  width: 80%;
}

.pick-pay-go-wrapper .custom-next-btn button {
  /* background: linear-gradient(120.54deg, #eb5b25, rgba(255, 255, 255, 0.5), #eb5b25); */
  background: linear-gradient(120deg,
      #eb5b25 30.09%,
      rgba(255, 255, 255, 0.5) 35.38%,
      #eb5b25 40.22%);
  border: 1px solid transparent;
  background-size: 300% 300%;
  border-radius: 2px;
  animation: shiningAnimation 1.5s infinite 3s;
}

@keyframes shiningAnimation {
  0% {
    background-position: 100% 0%;
  }

  /* 50% {
		background-position: 100% 50%;
	} */
  100% {
    background-position: 0% 100%;
  }
}

.pick-pay-go-instructions-wrapper .icon-wrapper,
.store-channel-wrapper .icon-wrapper {
  position: relative;
}

.store-channel-wrapper .active-icon svg path {
  fill: #fff;
}

.payment-done-animate svg .green-outline {
  animation: paymentDoneAnimation 0.5s linear infinite alternate;
  transform-origin: center center;
}

.payment-done-animate svg .tick {
  animation: paymentDoneAnimation2 0.5s linear infinite alternate;
  transform-origin: center center;
}

@keyframes paymentDoneAnimation {
  from {
    transform: scale(1, 1) rotate(0);
  }

  to {
    transform: scale(1.2, 1.2) rotate(10deg);
  }
}

@keyframes paymentDoneAnimation2 {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(0.5, 0.5);
  }
}

.store-channel-wrapper .icon-wrapper .dotted-line {
  position: absolute;
  top: 50%;
  width: 30vw;
  left: 100%;
}

.pick-pay-go-instructions-wrapper .icon-wrapper .dotted-line {
  position: absolute;
  height: 14vh;
  left: 50%;
}

.bar-code-scanner-wrapper {
  min-height: calc(100vh - 415px);
  overflow: hidden;
  /* position: relative; */
  max-height: 480px !important;
  z-index: 1;
}

.bar-code-scanner-wrapper video {
  /* position: relative; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: #000; */
  z-index: 99;
  /* background-color: #000; */
  /* position: absolute;
    left: 50%;
    top: 50%;
    width: 200%;
    height: 150%;
    background-color: #000;
    transform: translate(-50%,-50%); */
}

.bar-code-scanner-wrapper select {
  /* background-color: transparent;
  color: white;
  border-color: white;
  border-width: 1px;
  font-size: 16px;
  outline-style: none; */
  max-width: 180px !important;
  margin-top: 50px !important;
}

.cs_scanner_message {
  min-height: auto;
  /* padding-top: 96px; */
  position: ABSOLUTE;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bar-code-scanner-main-wrapper {
  position: relative;
  z-index: 9;
}

.cs_scanner_top_message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 999;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

.cs_scanner_btm_message {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 999;
  text-align: center;
}

.cs_scanner_btm_message span {
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  color: #fff;
}

.bar-code-scanner-wrapper svg path {
  fill: #ec5e28;
}

.bar-code-enter-wrapper input {
  background: rgba(219, 219, 219, 0.32);
  border-radius: 2px;
  width: 100%;
  padding: 18px 16px;
  color: #251000;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.56;
}

.cs-loader-box {
  min-height: 200px;
}

.cs-mini-loader-box {
  min-height: 138px;
}

.loader-animate {
  transform: translateY(-50%);
  -webkit-animation: loaderAnimation 1s linear infinite;
  animation: loaderAnimation 1s linear infinite;
}

@keyframes loaderAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

a.download_the_mobile_app_className {
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(0, 19, 37, 0.92);
}

@media screen and (min-width: 1024px) {
  .download-banner-wrapper .max-w-50 {
    max-width: 50% !important;
  }
}

@media screen and (min-width: 620px) {
  .download-banner-wrapper .w-70 {
    max-width: 100% !important;
  }

  .download-banner-wrapper .max-w-95 {
    max-width: 100% !important;
  }
}

.download-banner-wrapper .w-70 {
  max-width: 60%;
}

.download-banner-wrapper .max-w-95 {
  max-width: 95%;
}

.sort-filter {
  width: 300px;
}

.custom-red-info-icon svg path {
  fill: #e00000 !important;
}

@keyframes filterPopupAnimation {
  0% {
    transform: translate(0%, 100%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

.sort-filter .animate-filter-popup {
  z-index: 50;
  animation: filterPopupAnimation 400ms normal 0ms;
}

#view-all-result {
  position: fixed;
  top: 285px;
  width: 73%;
}

.dynamic-banner>div:first-child {
  height: 0px !important;
}

.dynamic-banner>div:nth-child(2) {
  position: initial !important;
}

.responsive-wrapper {
  position: initial !important;
}

.position-intial {
  position: relative !important;
}

/* store mapper css for internal script html */
.strmpr-search-results .strmpr-search-result .strmpr-field-bucket-general {
  color: black !important;
}

.strmpr-map .strmpr-map-popup {
  color: black !important;
}

.strmpr-search .strmpr-search-box-wrapper .strmpr-autocomplete .strmpr-autocomplete-suggestion {
  color: black !important;
}

@media (max-width: 1023px) {
  .mobile-tab {
    display: block;
  }

  .desktop-laptop {
    display: none;
  }

  .store-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .mobile-tab {
    display: none;
  }

  .desktop-laptop {
    display: block;
  }

  .store-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.selected-rating-btn {
  color: white !important;
  background: #ec5e28;
}

.selected-tags {
  border: 1.5px solid #eb5b25;
  background: rgba(235, 91, 37, 0.16);
  color: #ec5e28;
}

.feedback-container {
  width: 38%;
}

@media (max-width: 720px) {
  .feedback-container {
    width: 100%;
  }

  .hydrationStateStyle-desktop {
    margin: 12px;
  }
}

@media (max-width: 1030px) {
  .mobile-tab-top-category {
    display: block;
  }
}

@media (min-width: 1031px) {
  .mobile-tab-top-category {
    display: none;
  }
}

.skeletonLoader {
  --tw-bg-opacity: 1;
  background: rgb(226 232 240 / var(--tw-bg-opacity));
  background-size: 200% 100%;
  /* animation: loading 1.5s infinite; */
  height: 0;
  padding-bottom: 66.67%;
  /* 3:2 aspect ratio (300/200) */
}

.text-truncate-two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  max-height: calc(2 * 1.5 * 2);
  -webkit-line-clamp: 2;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.exchange-selection-wrapper .overflow-x-auto.hide-scrollbar-visibility {
  margin-bottom: 0;
}

/* .exchange-selection-wrapper .flex.items-center.space-x-2.pb-5 {
  padding-bottom: 0.5rem;
} */

.exchange-selection-wrapper .hide-swach-padding.order_size {
  padding-top: 0;
  padding-bottom: 0;
}

.swiper-container {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.swiper-container::-webkit-scrollbar {
  width: 0.5em;
  /* Set the width of the scrollbar */
}

.swiper-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set the background color of the thumb */
}

.swiper-container::-webkit-scrollbar-track {
  background-color: transparent;
  /* Set the background color of the track */
}

.swiper-prev-button::before,
.swiper-next-button::before {
  content: '';
  position: fixed;
  top: 44%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  transition: transform 0.3s ease;
}

.swiper-prev-button::before {
  /* left: 50%; */
  transform-origin: center center;
  transform: translate(17%, -35%) rotate(45deg);
}

.swiper-next-button::before {
  /* right: 50%; */
  transform-origin: center center;
  /* transform: translate(50%, -50%) rotate(45deg); */
  transform: translate(-24%, -39%) rotate(230deg);
}

/* .swiper-item {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.swiper-item.active-slide {
  opacity: 1; 
} */

.swiper-prev-button,
.swiper-next-button {
  position: fixed;
  top: 44%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-color: black;
  border: 2px solid;
  color: black;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 13;
}

.swiper-prev-button {
  left: 10px;
}

.swiper-next-button {
  right: 10px;
}

.swiper-list {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
}

.swiper-item {
  padding-right: 5px;
  flex-shrink: 0;
}

.swiper-item:last-child {
  padding-right: 0;
}

.video-image-grid .image-container.v-container-image:first-child .badge-top-span,
.grid-no-video .image-container:first-child .badge-top-span {
  display: block !important;
}

.badge-top-span .badge-span-info {
  display: none !important;
}

.badge-top-span .badge-span-info:first-child {
  display: inline-block !important;
}

@media screen and (max-width: 767px) {
  .badge-top-span span {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .offer-list-div {
    height: 32px;
    overflow: hidden;
  }

  .height-full {
    height: auto !important;
  }
}

.round-star {
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  text-align: center;
  user-select: none;
  width: 100%;
}

.round-star-container-web {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, minmax(50px, 1fr));
  gap: 4px;
  height: 50px;
}

.round-star-container-mobile {
  display: grid;
  grid-template-columns: repeat(6, minmax(44px, 1fr));
  gap: 4px;
}

.rating-box:nth-child(n + 7) {
  margin-inline-start: calc(50% + 4px);
}

.text-truncate-three-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  -webkit-line-clamp: 3;
}

.free-gift-popup-wrapper .swiper {
  position: relative;
  background: #fff;
}

.free-gift-popup-wrapper .swiper .swiper-wrapper {
  padding-bottom: 1.5rem;
}

.free-gift-popup-wrapper .swiper .swiper-pagination {
  position: absolute;
  bottom: 9px;
}

.free-gift-popup-wrapper .swiper .swiper-pagination .swiper-pagination-bullet {
  height: 6px;
  width: 6px;
}

.free-gift-popup-wrapper .swiper .swiper-pagination-bullet-active {
  background: #251000 !important;
}

.shimmer-effect>div {
  background: linear-gradient(120deg,
      transparent 30.09%,
      rgba(222, 56, 0, 0.2) 35.38%,
      transparent 40.22%);
  border: 1px solid transparent;
  background-size: 300% 300%;
  border-radius: 2px;
  animation: shiningAnimation 2s infinite 500ms;
}

@media only screen and (max-width: 767px) {
  .video-mob-width{
    width: 305px !important;
  }
  .top-strip-active-offers-wrapper {
    height: 20vw;
    /* 20% of viewport height */
  }

  .top-strip-active-offers-wrapper .swiper-pagination.swiper-pagination-bullets {
    left: 95% !important;
    transform: translate(-95%) !important;
    width: 15% !important;
  }

  .top-strip-active-offers-wrapper .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px !important;
  }

  .top-strip-active-offers-wrapper .swiper-pagination .swiper-pagination-bullet {
    background-color: #f6f2f2 !important;
    color: #f6f2f2 !important;
  }

  .top-strip-active-offers-wrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #808080 !important;
    color: #808080 !important;
  }
}

@media only screen and (min-width: 767px) {
  .top-strip-active-offers-wrapper .swiper-pagination.swiper-pagination-bullets {
    left: 88% !important;
    transform: translate(-88%) !important;
  }
}

.product-sticky-swiper .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}

.product-sticky-swiper .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-body-scroll {
  overflow-y: hidden;
}

@keyframes plpFiltersAnimation {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(48px);
  }
}

@keyframes plpFiltersAnimatio {
  0% {
    transform: translateY(48px);
  }

  100% {
    transform: translateY(0px);
  }
}

.plp-filters-rail {
  -webkit-animation: plpFiltersAnimatio 300ms alternate ease;
  animation: plpFiltersAnimatio 300ms alternate ease;
}

.plp-filters-rail.activePLPFiltersRail {
  -webkit-animation: plpFiltersAnimation 300ms alternate ease;
  animation: plpFiltersAnimation 300ms alternate ease;
  transform: translateY(48px);
}

.promo-box {
  box-shadow: 0px 2px 8px 0px #00000029;
  border: 1px solid #25100014;
}

.light-green-color {
  background: #00890e14;
}

.margin-y-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.index-2 {
  z-index: 2 !important;
}

.hide-banner-cls,
.form-hide-cls {
  display: none !important;
}

.form-hide-cls.form-active {
  display: block !important;
}

@media screen and (min-width: 1025px) {
  .desk-modal-position {
    top: 50%;
    left: 50%;
    height: auto;
    bottom: auto !important;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 500px;
  }

  .desk-modal-position .max-w-md {
    max-width: 100% !important;
  }
}

.darkMode #product-sticky-bar .text-black { color: #000 !important; }

.darkMode #product-sticky-bar .bg-white { background-color: #fff !important; }

.darkMode, .darkMode .bg-white, .darkMode .group:hover, .darkMode .bg-white:hover { background-color: black !important; }

.darkMode .text-white, .darkMode .text-black,.darkMode .text-gray-900, .darkMode .text-\[\#00890E\], .darkMode .text-primary, .darkMode .text-black:hover, .darkMode .text-primary:hover, .darkMode .group:hover .text-primary, .darkMode .text-white:hover { color: white !important; }

.darkMode .sprite-icon{ filter: invert(1) !important; }

.darkMode .sprite-icon.sprite-o-whislist{ filter: invert(0) !important; }

.darkMode .text-\[\#2510008f\] { 
  color: white !important;
  opacity: 0.7 !important;
}

.darkMode .line-through {
  text-decoration-line: line-through;
  text-decoration-color: whitesmoke !important;
  text-decoration-thickness: 1px !important
}

.darkMode .border-brown-light, .darkMode .border-gray-light {
  border-color: rgba(241, 236, 226, 0.32) !important;
}

.plpMsg3{ 
  color: #fff;
  background-color: #000;
  padding: 3px 5px;
  font-family: var(--font-sans) !important;
}

@keyframes slideIn {
  0% {
    top: -1000px;
  }
  100% {
    top: 0;
  }
}

@keyframes slideOut {
  0% {
    top: 0;
  }
  100% {
    top: -1000px;
  }
}

.header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: white;
  transition: top 0.5s ease;
}

.hideout {
  animation: slideOut 0.4s forwards;
}

.visible {
  animation: slideIn 0.4s forwards; 
}

.stick {
  width: 100%;
  position: sticky;
  z-index: 1000;
  top: 0;
  background-color: white;
  transition: top 0.5s ease;
}

.kp-autologin-toast {
  right: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 76px;
  background-color: #00890E;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.kp-autologin-toast.show {
  opacity: 1;
  transform: translateY(0);
}
.kp-autologin-toast.hide {
  opacity: 0;
  transform: translateY(-100%);
}

@media screen and (max-width: 767px) {
  .hide-scrollbar-visibility.scroll-bar-width-0::-webkit-scrollbar {
      width: 0px !important;
    }
  .typeform .mobile-banner {
    height: auto !important;
    min-height: 0 !important;
    object-fit: contain !important;
    object-position: center;
  }
}

@media screen and (min-width: 380px) and (max-width: 520px) {
  .typeform .mobile-banner {
    height: auto !important;
    min-height: 0 !important;
    object-fit: contain !important;
    object-position: center;
  }
}

#d2c-pass {
  color-scheme: light !important;
}

body .bg-main-gray {
  background: #f7f7f7 !important;
}

.padding-0-important {
  padding: 0 !important;
}

body .video-padding-0 {
  padding: 0 !important;
}