.sprite-icon {
	background-repeat: no-repeat;
	position: relative;
	display: inline-block;
}


.sprite-fb {
	background: url(/assets/sprite-icons/fb.svg) no-repeat;
	width: 30px;
	height: 30px;
}

.sprite-insta {
	background: url(/assets/sprite-icons/insta.svg) no-repeat;
	width: 28px;
	height: 28px;
}

.sprite-twitter {
	background: url(/assets/sprite-icons/twitter.svg) no-repeat;
	width: 30px;
	height: 28px;
}

.sprite-youtube {
	background: url(/assets/sprite-icons/youtube.svg) no-repeat;
	width: 30px;
	height: 26px;
}

.sprite-linkedin {
	background: url(/assets/sprite-icons/linkedin.svg) no-repeat;
	width: 28px;
	height: 28px;
}

.sprite-search {
	background: url(/assets/sprite-icons/search.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-heart {
	background: url(/assets/sprite-icons/heart.svg) no-repeat;
	width: 23px;
	height: 23px;
}

.sprite-cart {
	background: url(/assets/sprite-icons/cart.svg) no-repeat;
	width: 23px;
    height: 23px;
}

.sprite-copy {
	background: url(/assets/sprite-icons/copy.svg) no-repeat;
	width: 16px;
	height: 16px;
	top: 4px;
	margin-left: 4px;
}

.sprite-user {
	background: url(/assets/sprite-icons/user.svg) no-repeat;
	width: 23px;
	height: 23px;
}

.sprite-star {
	background: url(/assets/sprite-icons/star.svg) no-repeat;
	width: 11px;
    height: 11px;
}
.sprite-product-whislist {
	background: url(/assets/sprite-icons/product-heart.svg) no-repeat;
	width: 24px;
	height: 19px;
}

.sprite-whislist {
	background: url(/assets/sprite-icons/heart.svg) no-repeat;
	width: 24px;
	height: 24px;
}

.sprite-o-whislist {
	background: url(/assets/sprite-icons/o-whislist.svg) no-repeat;
	width: 19px;
	height: 18px;
}

.sprite-Menu-icon {
	background: url(/assets/sprite-icons/menu.svg) no-repeat;
	width: 17px;
	height: 17px;
}

.sprite-left-arrow {
	background: url(/assets/sprite-icons/left-arrow.svg) no-repeat;
	width: 18px;
    height: 16px;
}

.sprite-secuirty {
	background: url(/assets/sprite-icons/security.svg) no-repeat;
	width: 33px;
	height: 35px;
}

.sprite-right-white-arrow {
	background: url(/assets/sprite-icons/right-white-arrow.svg) no-repeat;
	width: 20px;
    height: 15px;
}

.sprite-add-icom {
	background: url(/assets/sprite-icons/add-icon.svg) no-repeat;
	width: 19px;
	height: 20px;
}

.sprite-return {
	background: url(/assets/sprite-icons/return.svg) no-repeat;
	width: 30px;
	height: 30px;
}

.sprite-visa {
	background: url(/assets/sprite-icons/visa.svg) no-repeat;
	width: 18px;
	height: 13px;
}

.sprite-gpay {
	background: url(/assets/sprite-icons/gpay.svg) no-repeat;
	width: 20px;
	height: 18px;
}

.sprite-phonepay {
	background: url(/assets/sprite-icons/phonepay.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-paytm {
	background: url(/assets/sprite-icons/paytm.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-bhim {
	background: url(/assets/sprite-icons/bhim.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-cred {
	background: url(/assets/sprite-icons/cred.svg) no-repeat;
	width: 25px;
	height: 20px;
}

.sprite-down {
	background: url(/assets/sprite-icons/down.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-cart-down {
	background: url(/assets/sprite-icons/down.svg) no-repeat;
	width: 20px;
	height: 14px;
}

.sprite-new-upi {
	background: url(/assets/sprite-icons/new-upi.svg) no-repeat;
	width: 19px;
	height: 20px;
}

.sprite-info {
	background: url(/assets/sprite-icons/info-black.svg) no-repeat;
	width: 19px;
	height: 20px;
}

.sprite-new-card {
	background: url(/assets/sprite-icons/new-card.svg) no-repeat;
	width: 21px;
	height: 21px;
}

.sprite-all-payment {
	background: url(/assets/sprite-icons/all-payment.svg) no-repeat;
	width: 158px;
	height: 13px;
}

.sprite-secure {
	background: url(/assets/sprite-icons/secure.svg) no-repeat;
	width: 10px;
	height: 11px;
}

.sprite-master {
	background: url(/assets/sprite-icons/master.svg) no-repeat;
	width: 19px;
	height: 18px;
}

.sprite-wallet {
	background: url(/assets/sprite-icons/wallet.svg) no-repeat;
	width: 18px;
    height: 18px;
}

.sprite-netbanking {
	background: url(/assets/sprite-icons/netbanking.svg) no-repeat;
	width: 19px;
	height: 19px;
}

.sprite-pay-cash {
	background: url(/assets/sprite-icons/pay-cash.svg) no-repeat;
	width: 20px;
	height: 14px;
}

.sprite-amazon-pay {
	background: url(/assets/sprite-icons/amazon-pay.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-mobikwik {
	background: url(/assets/sprite-icons/mobikwik.svg) no-repeat;
	width: 20px;
	height: 16px;
}

.sprite-hdfc {
	background: url(/assets/sprite-icons/hdfc.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-sbi {
	background: url(/assets/sprite-icons/sbi.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-icici {
	background: url(/assets/sprite-icons/icici.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-kotak {
	background: url(/assets/sprite-icons/kotak.svg) no-repeat;
	width: 20px;
	height: 18px;
}

.sprite-axis {
	background: url(/assets/sprite-icons/axis.svg) no-repeat;
	width: 20px;
	height: 18px;
}

.sprite-right-arrow-black {
	background: url(/assets/sprite-icons/right-arrow-black.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-payment-secure {
	background: url(/assets/sprite-icons/payment-secure.svg) no-repeat;
	width: 37px;
	height: 33px;
}

.sprite-blue-info {
	background: url(/assets/sprite-icons/blue-info.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-paytm-w {
	background: url(/assets/sprite-icons/paytm-w.svg) no-repeat;
	width: 20px;
	height: 18px;
}

.sprite-freecharge {
	background: url(/assets/sprite-icons/freecharge.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-secure-card-info {
	background: url(/assets/sprite-icons/secure-card-info.svg) no-repeat;
	width: 28px;
	height: 24px;
}

.sprite-upgrade-security {
	background: url(/assets/sprite-icons/payment-secure.svg) no-repeat;
	width: 29px;
	height: 26px;
}

.sprite-avoid {
	background: url(/assets/sprite-icons/avoid.svg) no-repeat;
	width: 28px;
	height: 20px;
}

.sprite-cvv {
	background: url(/assets/sprite-icons/cvv.svg) no-repeat;
	width: 63px;
	height: 66px;
}

.sprite-d-logo {
	background: url(/assets/sprite-icons/d-logo.svg) no-repeat;
	width: 31px;
	height: 24px;

}

.sprite-success-info {
	background: url(/assets/sprite-icons/success-info.svg) no-repeat;
	width: 63px;
	height: 73px;
}

.sprite-angry {
	background: url(/assets/sprite-icons/angry.svg) no-repeat;
	width: 24px;
	height: 24px;
}

.sprite-sad {
	background: url(/assets/sprite-icons/sad.svg) no-repeat;
	width: 24px;
	height: 24px;
}

.sprite-neutral {
	background: url(/assets/sprite-icons/neutral.svg) no-repeat;
	width: 24px;
	height: 24px;
}

.sprite-happy {
	background: url(/assets/sprite-icons/happy.svg) no-repeat;
	width: 24px;
	height: 24px;
}

.sprite-ginning {
	background: url(/assets/sprite-icons/ginning.svg) no-repeat;
	width: 26px;
	height: 26px;
}

.sprite-warning-info {
	background: url(/assets/sprite-icons/warning-info-white.svg) no-repeat;
	width: 61px;
	height: 61px;
}

.sprite-email {
	background: url(/assets/sprite-icons/email.svg) no-repeat;
	width: 26px;
	height: 26px;
}

.sprite-phone {
	background: url(/assets/sprite-icons/phone.svg) no-repeat;
	width: 10px;
	height: 15px;
}

.sprite-error-info {
	background: url(/assets/sprite-icons/error-info.svg) no-repeat;
	width: 59px;
	height: 65px;
}

.icon-reset {
	background: url(/assets/sprite-icons/reset-white.svg) no-repeat;
	width: 13px;
	height: 13px;
}

.filter-white {
	filter: brightness(0) invert(1);
}

.social-section {
	max-width: 300px;
	width: 100%;
}

/* .basket-gift {
	background-position: -21px -494px;
	width: 16px;
	height: 16px;
} */

.trash-icon {
	background: url(/assets/sprite-icons/trash.svg) no-repeat;
	width: 16px;
	height: 17px;
}

.gift-icon {
	background: url(/assets/sprite-icons/gift.svg) no-repeat;
	width: 15px;
	height: 15px;
}

.coupon-icon {
	background: url(/assets/sprite-icons/coupon.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.cross-icon {
	background: url(/assets/sprite-icons/cross.svg) no-repeat;
	width: 19px;
	height: 19px;
}

.currency-icon {
	background: url(/assets/sprite-icons/currency.svg) no-repeat;
	width: 14px;
	height: 15px;
}

.arrow-right {
	background: url(/assets/sprite-icons/right-arrow-black.svg) no-repeat;
	width: 17px;
    height: 15px;
	filter: invert(1);
}

.arrow-right-white {
	background: url(/assets/sprite-icons/right-white-arrow.svg) no-repeat;
	width: 17px;
    height: 15px;
}

.icon-location-orange {
	background: url(/assets/sprite-icons/location-orange.svg) no-repeat;
	width: 16px;
	height: 18px;
}

.icon-location-white {
	background: url(/assets/sprite-icons/location-white.svg) no-repeat;
	width: 22px;
	height: 22px;
}

.icon-payment-black {
	background: url(/assets/sprite-icons/payment-black.svg) no-repeat;
	width: 21px;
	height: 21px;
}

.sprite-cards {
	background: url(/assets/sprite-icons/cards.svg) no-repeat;
	width: 19px;
    height: 16px;
}

.sprite-upi {
	background: url(/assets/sprite-icons/upi.svg) no-repeat;
	width: 15px;
    height: 17px;
}

.sprite-icon.orders {
	background: url(/assets/sprite-icons/order.svg) no-repeat;
	width: 21px;
	height: 18px;
}

.sprite-icon.returns {
	background: url(/assets/sprite-icons/order.svg) no-repeat;
	width: 18px;
	height: 14px;
}

.sprite-icon.my.payment {
	background: url(/assets/sprite-icons/cards.svg) no-repeat;
	width: 21px;
	height: 16px;
}

.sprite-icon.wishlist {
	background: url(/assets/sprite-icons/wishlist-profile.svg) no-repeat;
	width: 19px;
	height: 18px;
}

.sprite-icon.my.profile {
	background: url(/assets/sprite-icons/user.svg) no-repeat;
	width: 23px;
	height: 24px;
}

.sprite-icon-black-user {
	background: url(/assets/sprite-icons/user.svg) no-repeat;
	width: 13px;
	height: 14px;
}

.sprite-icon.address {
	background: url(/assets/sprite-icons/location-black.svg) no-repeat;
	width: 21px;
	height: 18px;
}

.sprite-icon.share.your.bro.code {
	background: url(/assets/sprite-icons/gift.svg) no-repeat;
    width: 20px;
    height: 20px;
}

.sprite-icon-black-address {
	background: url(/assets/sprite-icons/location-black.svg) no-repeat;
	width: 12px;
	height: 17px;
}

.sprite-logout {
	background: url(/assets/sprite-icons/logout.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-close {
	background: url(/assets/sprite-icons/close.svg) no-repeat;
	width: 20px;
	height: 20px;
}

/* payment sprite icon css start*/
.sprite-rupay {
	background: url(/assets/sprite-icons/all-payment.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-visa {
	background: url(/assets/sprite-icons/visa.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-master-card {
	background: url(/assets/sprite-icons/master.svg) no-repeat;
	width: 19px;
	height: 18px;
}

.sprite-maestro {
	background: url(/assets/sprite-icons/maestro.svg) no-repeat;
	width: 19px;
	height: 18px;
}

.sprite-diners {
	background: url(/assets/sprite-icons/diners.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-amex {
	background: url(/assets/sprite-icons/amex.svg) no-repeat;
	width: 18px;
	height: 18px;
}

/* payment sprite icon css end*/
.sprite-file-plus {
	background: url(/assets/sprite-icons/file-plus.svg) no-repeat;
	width: 23px;
	height: 23px;
}

.sprite-white-check {
	background: url(/assets/sprite-icons/white-check.svg) no-repeat;
	width: 11px;
	height: 8px;
}

.sprite-up-arrow {
	background: url(/assets/sprite-icons/up-arrow.svg) no-repeat;
	width: 19px;
	height: 20px;
}

.icon-filter-opacity {
	filter: opacity(0.6);
}

.sprite-delivery-info-icon {
	background: url(/assets/sprite-icons/delivery-info.svg) no-repeat;
	width: 35px;
	height: 28px;
	transform: scale(0.8);
}

.pay-delivery-info-icon {
	background: url(/assets/sprite-icons/pay-delivery.svg) no-repeat;
	width: 25px;
	height: 22px;
}

.sprite-nav-plus {
	background: url(/assets/sprite-icons/nav-plus.svg) no-repeat;
	width: 17px;
	height: 17px;
}

.sprite-nav-minus {
	background: url(/assets/sprite-icons/nav-minus.svg) no-repeat;
	width: 19px;
    height: 16px;
}

.sprite-nav-right-arrow {
	background: url(/assets/sprite-icons/right-arrow-black.svg) no-repeat;
	width: 17px;
	height: 16px;
}

.sprite-orange-right-arrow {
	background: url(/assets/sprite-icons/right-arrow-orange.svg) no-repeat;
	width: 17px;
	height: 18px;
}

.sprite-other-bank {
	background: url(/assets/sprite-icons/other-bank.svg) no-repeat;
	width: 19px;
	height: 19px;
}

.sprite-light-close {
	background: url(/assets/sprite-icons/close.svg) no-repeat;
	width: 13px;
	height: 14px;
}

.sprite-white-whatsapp {
	background: url(/assets/sprite-icons/white-whatsapp.svg) no-repeat;
	width: 18px;
	height: 18px;
}

.sprite-down-orange-arrow {
	background: url(/assets/sprite-icons/down-arrow-orange.svg) no-repeat;
	width: 19px;
	height: 19px;
}

.sprite-track-icon {
	background: url(/assets/sprite-icons/track.svg) no-repeat;
	width: 20px;
	height: 18px;
}

.sprite-help-icon {
	background: url(/assets/sprite-icons/help.svg) no-repeat;
	width: 20px;
    height: 20px;
}

.sprite-blog-icon {
	background: url(/assets/sprite-icons/blog.svg) no-repeat;
	width: 20px;
	height: 20px;
}

.sprite-da-icon {
	background: url(/assets/sprite-icons/d-logo.svg) no-repeat;
	width: 18px;
	height: 13px;
}

.sprite-help-question {
	background: url(/assets/sprite-icons/help-question.svg) no-repeat;
	width: 19px;
	height: 20px;
}
