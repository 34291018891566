.border-brown-light {
  border-color: #f1ece2 !important;
}


.border-gray-light {
  border-color: #d9d9d9 !important;
}

.text-danger {
  color: #e00000 !important;
}

.text-8 {
  font-size: 8px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-brown-light {
  color: rgba(37, 16, 0, 0.56) !important;
}

.text-brown-semilight {
  color: rgba(37, 16, 0, 0.64) !important;
}

.text-green {
  color: #00890e !important;
}

.text-blue {
  color: #006dff !important;
}

.text-secondary {
  color: rgba(0, 19, 37, 0.64) !important;
}

.text-secondary-full-opacity {
  color: rgba(0, 19, 37, 0.92);
}

.text-primary {
  color: #251000 !important;
}

.text-gray-dark {
  color: rgba(0, 19, 37, 0.92) !important;
}

.text-gray {
  color: rgba(37, 16, 0, 0.64);
}

.bg-dark-grey {
  background: rgb(219, 219, 219, 0.36);
}

.text-dark-red {
  color: #c10000;
}

.min-height-btn-r {
  min-height: 52px;
}

@media screen and (min-width: 1024px) {
  .card-hover-shadow {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25) !important;
  }

  .group:hover .sm\:group-hover\:card-hover-shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
    transform: scale(1.05);
    border-radius: 4px !important;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 10;
  }
}

.top-108 {
  top: 79px !important;
  z-index: 11;
}

@media screen and (max-width: 767px) {
  body .imae-container .swiper.swiper-initialized.swiper-horizontal.swiper-ios,
  body
    .imae-container
    .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
    padding-bottom: 0px !important;
  }

  .overlow-y-mob-fixed {
    overflow: hidden;
    position: fixed;
  }

  .text-24 {
    font-size: 20px !important;
  }

  .text-14 {
    font-size: 12px !important;
  }

  .top-108 {
    top: 48px !important;
    z-index: 4;
  }

  .font-login-mobile {
    font-size: 14px !important;
  }

  .sm-text-16 {
    font-size: 16px !important;
  }

  .xs-text-12 {
    font-size: 12px !important;
  }

  .xs-text-16 {
    font-size: 16px !important;
  }

  .xs-text-14 {
    font-size: 14px !important;
  }

  .xs-text-20 {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1280px) {
  .max-w-9xl {
    width: 70rem !important;
    margin: 0 auto;
  }
}

.confirmation-border {
  position: relative;
}

.confirmation-border:after {
  position: absolute;
  bottom: 0;
  content: url(https://img.damensch.com/icons/cnf-bar.png);
  width: 100%;
  height: 10px;
  left: 0px;
  z-index: 10;
}

.waiting-border {
  position: relative;
}

.waiting-border:after {
  position: absolute;
  bottom: 0;
  content: url(https://img.damensch.com/icons/waithing-bar.png);
  width: 100%;
  height: 10px;
  left: 0px;
  z-index: 10;
}

@media screen and (min-width: 1620px) {
  .padding-xl {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

.span-dot-eta {
  padding: 0 3px;
}

@media screen and (max-width: 767px) {
  .mob-border-none-pdp {
    border-top: 0 !important;
    padding-top: 0;
  }

  .mobile-p-m-none {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .mob-stck-font {
    font-size: 10px !important;
    top: -5px;
    line-height: 0;
  }

  .mobile-p-m-none {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .mob-font-14 {
    font-size: 13px;
    font-weight: 700;
  }

  .span-dot-eta {
    display: none;
  }

  .eta-span {
    display: block;
    padding: 5px 0;
  }

  .mobb-z-9 {
    z-index: 9;
  }

  .z-index-overlay {
    z-index: 4;
  }

  .max-panel.mob-max-panel {
    max-height: 380px;
    min-height: 100%;
  }

  .mob-l-r-padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .chk-mob-relative {
    position: relative;
  }

  .mob-padding-none-chk {
    padding-bottom: 0;
  }

  .min-h-40.mob-none-min-height {
    min-height: 0;
  }

  .mob-left-slider-padding {
    padding-left: 1rem;
  }

  .text-mob-14 {
    font-size: 14px !important;
  }

  .mob-text-xs {
    font-size: 10px !important;
  }

  .mob-width-full-hero {
    width: 100%;
  }

  .mob-right-logo-padding {
    padding-right: 5px !important;
  }

  .mob-left-logo-padding {
    padding-left: 24px !important;
  }

  .mob-flex-width {
    display: flex;
    justify-content: center;
  }

  .h-auto-mob {
    height: auto !important;
  }

  .text-m-16 {
    font-size: 16px !important;
  }
}

.nav-dropdown-bg {
  background: linear-gradient(
    103.66deg,
    #f1ece2 0.55%,
    rgba(233, 229, 223, 0.4) 100%
  );
}

.nav-border {
  border-bottom: 1px solid #f1ece0;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.min-height-ratng-24 {
  min-height: 24px;
}

.sort-top-mobile-sticky {
  top: 46px;
  left: 0px;
  z-index: 4;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* padding: 0.5rem 0; */
}

.border-none-pdp {
  border: 0 !important;
}

.font-color-weight-300 {
  font-weight: 300;
}

.des-hide-top .swiper-button-next,
.des-hide-top .swiper-button-prev {
  display: none;
}

.quickview_attributes .order_colour {
  display: none;
}

.hidden-desk-mob {
  display: none !important;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .tab-visible {
    display: block !important;
    margin-bottom: 10px !important;
  }
}

.desk-hero-width-full {
  width: 100%;
}

.mob-f-modal-width {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .mob-dlex-voc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mob-voch-img {
    display: inline-block;
    width: 200px;
  }

  .mob-form-v .mob-ui-btn {
    display: flex;
    justify-content: space-between;
  }

  .mob-form-v .mob-ui-btn .mob-btn-v {
    height: 56px !important;
    line-height: 56px !important;
  }

  .xs-text-10 {
    font-size: 11px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .mob-font-small-screen {
    font-size: 12px;
  }
}

.promo-bg {
  background: rgba(0, 137, 69, 0.1);
  border: 1px dashed #00890e;
  border-radius: 2px !important;
}

.new-promo-bg {
  border-radius: 2px;
  border: 1.5px solid rgba(37, 16, 0, 0.16);
  background: linear-gradient(0deg, rgba(236, 94, 40, 0.08) 0%, rgba(236, 94, 40, 0.08) 100%), #FFF;
  /* background: #fff; */
}

.new-coupon-design img {
  /* height: 36px; */
  width: 50px;
  object-fit: contain !important;
}

.text-coupon-light-black {
  color: rgba(37, 16, 0, 0.72);
}

.promo-bg:hover {
  background: rgba(0, 137, 69, 0.1);
  border: 1px dashed #00890e;
}

.coupon-panel {
  width: auto;
  background: rgba(0, 137, 69, 0.1);
  border: 1px dashed #00890e;
  border-radius: 2px !important;
  position: relative;
  display: table;
  justify-items: end;
  text-align: left;
  margin: 0px auto;
  float: right;
}

.new-coupon-panel {
  /* width: auto; */
  /* background: rgba(0, 137, 69, 0.1); */
  border: 1px dashed #eb5b25;
  border-radius: 4px !important;
  position: relative;
  /* display: table; */
  justify-items: end;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
  width: fit-content;
}

.coupon-text {
  display: inline-block;
  width: auto;
  color: rgba(0, 0, 0, 0.92);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px 5px 10px;
  /* identical to box height, or 143% */
}

.new-coupon-text {
  display: inline-block;
  width: auto;
  color: rgba(37, 16, 0, 0.72);
  font-style: normal;
  font-size: 0.75rem;
  font-weight: 500;
  /* identical to box height, or 143% */
}

.new-coupon-copy {
  display: inline-block;
  border-left: 1px dashed #eb5b25;
  color: #eb5b25;
  text-align: center;
  justify-content: center;
  /* padding: 5px 10px; */
  border-radius: 4px !important;
}

.coupon-copy {
  display: inline-block;
  border-left: 1px dashed #00890e;
  color: #00890e;
  text-align: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 2px !important;
}

@media screen and (max-width: 767px) {
  .mob-w-45-img {
    width: 45px;
  }
  .mob-tab-scroll {
    padding-right: 40px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
  }
  .coupon-panel {
    text-align: left;
    margin: 0px auto;
    float: left;
  }
}

.bg-sky-offer {
  background: rgba(0, 109, 255, 0.08);
  border: 1px solid rgba(0, 109, 255, 0.16);
  border-radius: 2px;
}
.pl-10-view {
  padding-left: 10px !important;
}
.top-img-15 {
  top: 15px;
}
.top-90.top-badge-ps {
  top: 12px;
}
.faq-container {
  max-width: 690px;
}
.tab-btn-panel {
  width: auto;
  padding: 10px 12px !important;
  display: inline-block;
  margin-right: 10px;
  box-sizing: border-box;
}
.tab-btn-panel:last-child {
  margin-right: 0;
}
.tab-btn-panel h2 {
  color: #251000;
  font-weight: 400;
}
.tab-active.tab-active-panel h2 {
  color: #251000;
  font-weight: 500;
}
.tab-btn-panel {
  border: 1px solid rgba(37, 16, 0, 0.16) !important;
}
.tab-active.tab-active-panel {
  border: 1.5px solid #251000 !important;
}
.hidden-icon {
  display: none !important;
}
.border-btn-color {
  border: 1.5px solid rgba(0, 0, 0, 0.16);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .tablet-flex-visible {
    display: flex;
  }
  .tab-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    -moz-column-gap: 0rem;
    column-gap: 0;
  }
  .tab-visible.tab-visible-grid {
    display: grid !important;
  }
}
.text-heading-width {
  max-width: 450px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .cls-min-height {
    min-height: 148px !important;
  }
}
.cart-url-button .cart-image {
  display: none !important;
}
.cart-url-button button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
